import React, { Component } from 'react'
import Header from './header'
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

function importAll2(r) {
    let images = [];
    r.keys().map((item, index) => {
        return images.push(item.replace('./', 'images/offerings/key/'))
    });
    return images;
}

const tech2 = importAll2(require.context('../../public/images/offerings/key', false, /\.(png|jpe?g|svg)$/));

export class DigitalPage extends Component {
    state = {
        active: '00',
        slideImg: ['images/analys-data-right-hand.png']
    }
    render() {
        const settingsTech = {
            dots: false,
            infinite: true,
            autoplay: true,
            speed: 2000,
            autoplaySpeed: 1000,
            slidesToShow: 4,
            slidesToScroll: 1,
            arrows: false,
            responsive: [
                {
                    breakpoint: 1024,
                    settings: {
                        slidesToShow: 3,
                        slidesToScroll: 3,
                        infinite: true,
                    }
                },
                {
                    breakpoint: 790,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 2,
                        initialSlide: 2
                    }
                },
                {
                    breakpoint: 480,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1
                    }
                }
            ]
        };
        return (
            <div>
                <Header/>
                <div className="digitrans">
                  <h3 className="title">DIGITAL TRANSFORMATION</h3>
                  <div className="box-container">
                  <div className="box">
                      <div>
                          <img src="images/offerings/1.png" width="150px" alt=""/>
                      </div>
                      <div className="text">
                        Customer​ Experience (CX)​ Advisory
                      </div>
                      <div className="text2">
                        Specializes in digital transformation – customer experience advisory, user journey mapping, paint points & expectations. Identify opportunity areas, recommend key solutions
                      </div>
                  </div>
                  <div className="box">
                      <div>
                          <img src="images/offerings/2.png" width="150px" alt=""/>
                      </div>
                      <div className="text">
                        Application Development: Mobile Apps, Web, IoT, Custom Applications
                      </div>
                      <div className="text2">
                        Open-source framework-based web / mobile application development, enterprise development support such as e-SIM, integration with core telecom applications
                      </div>
                  </div>
                  <div className="box">
                      <div style={{display:"flex", justifyContent: 'center'}}>
                          <img src="images/offerings/3.png" width="150px" alt=""/>
                          <img src="images/offerings/4.png" width="150px" alt=""/>
                      </div>
                      <div className="text">
                        Digital Smarts<br/>​(Dashboards and Omnichannel Chatbots)
                      </div>
                      <div className="text2">
                      Data-driven insights and analytics​ Data visualization and dashboards​ AI-driven deep learning and predictive models
                      </div>
                  </div>
                  </div>
                </div>
                <div className="client-container">
                    <span className="title-tech" style={{fontSize:'35pt'}}>
                        KEY TECHNOLOGIES
                            </span>
                    <Slider {...settingsTech}>
                        {tech2.map((image, i) => {
                            return <img src={image} alt="" className="image-slide" />
                        })}
                    </Slider>
                </div>
            </div>
        )
    }
}

export default DigitalPage
