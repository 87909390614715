import React, { Component } from 'react'
import Header from './header';
import { getJob } from "../actions/data";
import { postUserJob } from "../actions/data";
import { connect } from "react-redux";
import { func } from "prop-types";
import { TextField } from '@mui/material';
import Modal from 'react-modal';

const customStyles = {
    content: {

    }
};
class CareerClass extends Component {

    constructor(props) {
        super(props);
        this.state = {
            opened: true,
            selected: '',
            firstname: '',
            lastname: '',
            email: '',
            phone: '',
            message: '',
            job_id: '2',
            fileUploadState: "",
            file: null,
            showModal: false,
            data: []
        }
        this.handleOpenModal = this.handleOpenModal.bind(this);
        this.handleCloseModal = this.handleCloseModal.bind(this);
    }

    handleOpenModal(key, Obj) {
        this.setState({ showModal: true });
        this.setState({ job_id: Obj.id, selected: key });
        this.setState({ data: Obj });
    }

    handleCloseModal() {
        this.setState({ showModal: false });
    }
    componentDidMount = () => {
        const { fetchJob } = this.props;
        fetchJob();
        this.setState({ job: this.props.get_job });
    };

    handleOpentext = (key, Obj) => {
        this.setState({ opened: !this.state.opened, selected: key });
        this.setState({ job_id: Obj.id, selected: key });
    };

    handleUpload = event => {
        this.setState({ file: event.target.files[0] });
    };
    handleChangeFirstname = event => {
        this.setState({ firstname: event.target.value });
    };
    handleChangeLastname = event => {
        this.setState({ lastname: event.target.value });
    };
    handleChangeEmail = event => {
        this.setState({ email: event.target.value });
    };
    handleChangeTPhone = event => {
        this.setState({ phone: event.target.value });
    };
    handleChangeMessage = event => {
        this.setState({ message: event.target.value });
    };
    handleSubmit = () => {
        const { insertuserjob } = this.props;
        let post_user_job_data = {
            firstname: this.state.firstname,
            lastname: this.state.lastname,
            email: this.state.email,
            phone: this.state.phone,
            message: this.state.message,
            job_id: this.state.job_id,
            file: this.state.file
        };


        insertuserjob(post_user_job_data);
    };
    render() {

        const {
            get_job,
            post_user_job,
            error } = this.props;
        const jobs = [...get_job]
        
        return (
            <div>
                <Header />
                <div id="Career">
                    <div className="career" >
                        <div className="career-banner">
                            <h1 className="text">COME WORK WITH US!</h1>
                        </div>
                    </div>
                    <img src="images/website.jpg" alt="" className="img-career" />
                    <div className="position-open">
                        <div>
                        </div>
                        <div>
                            <h3 className="subtitle" style={{marginTop:'25px'}}>We at PGI Data believe are continuously embracing emerging technology 
                            and hiring for various job roles like full stack developers, 
                            data scientists, database engineers, cloud architects and many more.
                            <br/><br/> Feel free to share your resume with us. If you know anyone who is looking out for a job, please refer for a better reach.</h3>
                            <ol >
                                {jobs.map((Obj, i) => {
                                    return (
                                        <li key={i} className="title-career" style={{ marginTop: '40px' }}>
                                            <div onClick={() => this.handleOpenModal(i, Obj)}>
                                                {Obj.job_desc}
                                                <h3 className="subtitle2">Location: {Obj.location} | Minimum Experience: {Obj.min_exp}+ years</h3>
                                            </div>
                                            {/* {!this.state.opened && this.state.selected === i && */}
                                            <Modal
                                                isOpen={this.state.showModal}
                                                style={customStyles}
                                                contentLabel="Minimal Modal Example"
                                            >
                                                <div >
                                                    <h2 className="subtitle2" style={{ fontWeight: "Bold", textTransform: 'capitalize' }}>{this.state.data.job_desc}</h2>
                                                    <h3 className="subtitle2" style={{ fontWeight: "Bold", textTransform: 'capitalize' }}>Location: {this.state.data.location} | Minimum Experience: {this.state.data.min_exp}+ years</h3>
                                                </div>
                                                <div className="obj">
                                                    <h3 className="subtitle3">{this.state.data.text}</h3>
                                                    <div className="form-career">
                                                        <TextField
                                                            value={this.state.name}
                                                            onChange={event => this.handleChangeFirstname(event)}
                                                            className="forms"
                                                            type="text"
                                                            id="firstname"
                                                            name="Job Title"
                                                            placeholder="Enter First Name"
                                                            variant="outlined" />
                                                        <TextField
                                                            value={this.state.name}
                                                            onChange={event => this.handleChangeLastname(event)}
                                                            className="forms"
                                                            type="text"
                                                            id="lastname"
                                                            name="Job Title"
                                                            placeholder="Enter Last Name"
                                                            variant="outlined" />
                                                        <TextField
                                                            value={this.state.name}
                                                            onChange={event => this.handleChangeEmail(event)}
                                                            className="forms"
                                                            type="text"
                                                            id="email"
                                                            name="Job Title"
                                                            placeholder="Enter Email Id"
                                                            variant="outlined" />
                                                        <TextField
                                                            value={this.state.name}
                                                            onChange={event => this.handleChangeTPhone(event)}
                                                            className="forms"
                                                            type="number"
                                                            id="phone"
                                                            name="Job Title"
                                                            placeholder="Enter Phone Number"
                                                            variant="outlined" />
                                                        <TextField
                                                            value={this.state.name}
                                                            onChange={event => this.handleChangeMessage(event)}
                                                            multiline={true}
                                                            rows={5}
                                                            className="forms"
                                                            type="text"
                                                            id="message"
                                                            name="Job Title"
                                                            placeholder="Enter Message"
                                                            variant="outlined" />

                                                        <label className="label-text" for="file-button">Choose Your Resume</label>
                                                        <input
                                                            onChange={e => this.handleUpload(e)}
                                                            accept="image/jpeg,image/gif,image/png,application/pdf,image/x-eps"
                                                            id="file-button"
                                                            multiple
                                                            type="file"
                                                        />
                                                        <div className="button-modal">
                                                            <button onClick={() => this.handleSubmit()} type="submit" value="Check" className="login2">Submit</button>
                                                            <button onClick={this.handleCloseModal} className="close-modal">Close Modal</button>
                                                        </div>
                                                        {post_user_job.status === "OK" ?
                                                                <div className="alertD">
                                                                    <h4 className="text-alerts">Success Input the data</h4>
                                                                </div>
                                                                : error ?
                                                                    <div className="alertDD">
                                                                        <h4 className="text-alerts">Failed Input the data</h4>
                                                                    </div>
                                                                    : null}

                                                        <div className="locate">
                                                            <h4 className="fa fa-map-pin" style={{ marginRight: "10px" }}> </h4>
                                                            <h4 style={{ background: "#FFCC00", padding: '5px' }}>Location - {Obj.location}</h4>
                                                        </div>

                                                    </div>
                                                </div>


                                            </Modal>

                                        </li>
                                    )
                                })}

                            </ol>

                        </div>
                        <div>
                            <h1 className="title">Apply now</h1>
                            <h2 className="subtitle" >E-mail us your resume at <a style={{ color: "#001E5E" }} href="mailto:info@pgi-data.com">info@pgi-data.com</a></h2>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
CareerClass.propTypes = {
    fetchJob: func.isRequired,
    insertuserjob: func.isRequired
};

const mapStateToProps = ({ dataPGI: { get_job, post_user_job, pending, error } }) => ({
    get_job,
    post_user_job,
    pending,
    error
});

const mapDispatchToProps = dispatch => ({
    fetchJob: () => dispatch(getJob.request()),
    insertuserjob: post_user_job_data => dispatch(postUserJob.request(post_user_job_data)),
});
const Career = connect(mapStateToProps, mapDispatchToProps)(CareerClass)

export default Career;