import React, { Component } from 'react'
import ImageGallery from 'react-image-gallery';

  const imgList = [];
 
class Event extends Component {

    componentDidMount = () => {
        this.props.event.forEach(function (item, index) {
              imgList.push({
                  original : 'http://pgi-data.com/data/images/' + item.image,
                  thumbnail : 'http://pgi-data.com/data/images/' + item.image,
            })
          });
    }

    render() { 
               
        return (
        <div>
            <div className='event-header'>
            </div>
            <div className="blog2">
                <div className="content">  
                    <div className="boxyellows">
                        <span className="headings">Due to covid-19, PGI Data is currently not hosting any events.
                         We will be back soon with a lot of interesting contents. Stay tuned and follow us</span>
                        {/* <span className="subheading">Month 01, 2020 | 11:00 hours</span>
                        <span className="subheading">In association with : Optional  OR Host : Name Surname</span>
                        <span className="content">
                        Lorem Ipsum is simply dummy text of the printing and typesetting industry. 
                        It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, 
                        software like Aldus PageMaker of Lorem Ipsum.
                        </span> */}
                    </div>
                    <span className="title3">Events Gallery</span>
                    <div className="slidere">
                  
                    <ImageGallery 
                    items={imgList}
                    showFullscreenButton={false}
                    showPlayButton={false}
                    infinite= {true}
                    lazyLoad= {true}
                    showNav = {false}
                    autoPlay = {true}
                     />
                    </div>             
                </div>
            </div>
            </div>
        )
    }
}

export default Event
