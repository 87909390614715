import { handleActions } from "redux-actions";
import { postJob } from "../actions/data";
import { postUserJob } from "../actions/data";
import { getJob } from "../actions/data";
import { getUserJob } from "../actions/data";
import { getCvUser } from "../actions/data";
import { getInsight } from "../actions/data";
import { postInsight } from "../actions/data";
import { updateInsight } from "../actions/data";
import { deleteInsight } from "../actions/data";

const INITIAL_STATE = {
  post_job: "",
  get_job: "",
  cv:"",
  get_user_job: "",
  post_user_job:"",
  insertuserjob: "",
  post_insight: "",
  update_insight: "",
  get_insight: [],
  delete_insight: "",
  pending: true,
  error: false
};

const dataPGI = handleActions(
  {
   
    [postUserJob.REQUEST]: (state, { payload }) => ({
      ...state,
      firstname: payload.firstname,
      lastname: payload.lastname,
      email: payload.email,
      phone: payload.phone,
      message: payload.message,
      job_id: payload.job_id,
      file: payload.file
    }),
    [postUserJob.SUCCESS]: (state, { payload }) => ({
      ...state,
      post_user_job: payload.data,
      pending: false,
      error: false
    }),
    [postUserJob.FAILURE]: state => ({
      ...state,
      error: true,
      pending: false
    }),
    [postJob.REQUEST]: (state, { payload }) => ({
      ...state,
      location: payload.location,
      experience: payload.experience,
      jobdesc: payload.jobdesc,
      desc: payload.desc
    }),
    [postJob.SUCCESS]: (state, { payload }) => ({
      ...state,
      post_job: payload.data,
      pending: false,
      error: false
    }),
    [postJob.FAILURE]: state => ({
      ...state,
      error: true,
      pending: false
    }),
    [postInsight.REQUEST]: (state, { payload }) => ({
      ...state,
      title: payload.title,
      subtitle: payload.subtitle,
      shortdesc: payload.shortdesc,
      url: payload.url,
      image: payload.image,
      file: payload.file,
      category: payload.category
    }),
    [postInsight.SUCCESS]: (state, { payload }) => ({
      ...state,
      post_insight: payload.data,
      pending: false,
      error: false
    }),
    [postInsight.FAILURE]: state => ({
      ...state,
      error: true,
      pending: false
    }),
    [updateInsight.REQUEST]: (state, { payload }) => ({
      ...state,
      id: payload.id,
      title: payload.title,
      subtitle: payload.subtitle,
      shortdesc: payload.shortdesc,
      url: payload.url,
      image: payload.image,
      file: payload.file,
      category: payload.category
    }),
    [updateInsight.SUCCESS]: (state, { payload }) => ({
      ...state,
      update_insight: payload.data,
      pending: false,
      error: false
    }),
    [updateInsight.FAILURE]: state => ({
      ...state,
      error: true,
      pending: false
    }),
    [getJob.REQUEST]: state => ({
      ...state
    }),
    [getJob.SUCCESS]: (state, { payload }) => ({
      ...state,
      get_job: payload.data,
      pending: false,
      error: false
    }),
    [getJob.FAILURE]: state => ({
      ...state,
      error: true,
      pending: false
    }),
    [getInsight.REQUEST]: state => ({
      ...state
    }),
    [getInsight.SUCCESS]: (state, { payload }) => ({
      ...state,
      get_insight: payload.data,
      pending: false,
      error: false
    }),
    [getInsight.FAILURE]: state => ({
      ...state,
      error: true,
      pending: false
    }),
    [getUserJob.REQUEST]: state => ({
      ...state
    }),
    [getUserJob.SUCCESS]: (state, { payload }) => ({
      ...state,
      get_user_job: payload.data,
      pending: false,
      error: false
    }),
    [getUserJob.FAILURE]: state => ({
      ...state,
      error: true,
      pending: false
    }),
    [getCvUser.REQUEST]: state => ({
      ...state,
      // file_name: payload.file_name,
    }),
    [getCvUser.SUCCESS]: (state, { payload }) => ({
      ...state,
      cv: payload.data,
      pending: false,
      error: false
    }),
    [getCvUser.FAILURE]: state => ({
      ...state,
      error: true,
      pending: false
    }),
    [deleteInsight.REQUEST]: (state, { payload }) => ({
      ...state,
      id: payload.id
    }),
    [deleteInsight.SUCCESS]: (state, { payload }) => ({
      ...state,
      delete_insight: payload.data,
      pending: false,
      error: false
    }),
    [deleteInsight.FAILURE]: state => ({
      ...state,
      error: true,
      pending: false
    }),
  },

  INITIAL_STATE
);

export default dataPGI;
