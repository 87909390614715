import React, { Component } from 'react'
import { withRouter } from '../../helpers/withRouter'; 
import { connect } from "react-redux";
import { func } from "prop-types";
import { postLogin } from "../../actions/login";
import { TextField } from '@mui/material';

 class LoginClass extends Component {
    constructor(props) {
        super(props);
        this.state = {
            username: "",
            password: "",
            passerr: false,
            success: false
        };
    }
    componentDidMount = () => {
      localStorage.setItem("page", "1");
      const user = localStorage.getItem('pgiusername');
      if (user !== null) {
          this.props.navigate("/dashboard")
      }

  };
    handleChangeName = event => {
        this.setState({ username: event.target.value });
      };
      handleChangePass = event => {
        this.setState({ password: event.target.value });
      };

      handleSubmit = () => {
        const { login } = this.props;
        let user_login = {
          username: this.state.username,
          password: this.state.password
        };
        login(user_login);
      };
      componentWillReceiveProps(nextProps){
        if(nextProps.response_login.status === "OK" ){
          //Perform some operation
          this.props.navigate("/dashboard");
          localStorage.setItem("pgiusername", this.props.response_login.data !== undefined ? this.props.response_login.data.username:null);
        }
      }
    render() {
      const {response_login} = this.props;
        return (
            <div className="login-component">
                <div className="form">
                    <h1 className="login-title">Login</h1>
                    <form>
                        <TextField
                            value={this.state.name}
                            onChange={event => this.handleChangeName(event)}
                            className="forms"
                            type="text" id="fname"
                            name="firstname"
                            placeholder="Username"
                            variant="outlined" />
                        <TextField
                            className="forms"
                            type="password"
                            id="mail"
                            value={this.state.email}
                            onChange={event => this.handleChangePass(event)}
                            name="E-mail"
                            placeholder="Password"
                            variant="outlined" />

                        <button onClick={() => this.handleSubmit()} type="button" value="Check" className="login">Login</button>
                        {response_login.status === 'Error' &&
                            <div className="alertDD">
                                <p className="text-alerts">“Failed To Login”</p>
                            </div>}

                    </form>

                </div>
            </div>
        )
    }
}
LoginClass.propTypes = {
    login: func.isRequired
  };
  
  const mapStateToProps = ({ dologin: { response_login, pending, error } }) => ({
    response_login,
    pending,
    error
  });
  
  const mapDispatchToProps = dispatch => ({
    login: user_login => dispatch(postLogin.request(user_login))
  });
  
  const Login = connect(mapStateToProps, mapDispatchToProps)(LoginClass)
  
  export default withRouter(Login);
  
