import React, { Component } from "react";
import { withRouter } from "../helpers/withRouter";

class Solution extends Component {

  service() {
    this.props.navigate("/services")
  }

  render() {
    return (
  <div style={{ background:' #001E5E'}}>
      <div id="SOLUTIONSPACE" className="solution-component">
          <div className="solution">
            <h1 className="init">SOLUTION SPACE</h1>
            <div className="text-solution" >
              Our Data Science expertise has helped build customer acquisition & retention, customer lifetime value, customer credit risk, credit rating, demand forecasting, supply chain and logistics management, pricing and offer optimization.
          <br></br>
              <br></br>
          PGI Data has worked for multiple industries to improve customer loyalty, minimize operational cost, improve customer satisfaction and maximize profitability.
        </div>
            <div>
              <button className="readmore2" onClick={() => this.service()}>Read More</button>

            </div>
          </div>
          <div>
            <img src="images/solution-space/solution.png" className="image-solution" alt="" />
          </div>
      </div>
  </div>
    );
  }
}

export default withRouter(Solution);
