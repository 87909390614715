import React, { Component } from 'react'
import Contact from './Contact'
import Header from './header'

export class ContactPage extends Component {
   
    render() {
        return (
            <div id="Contact">
                <Header/>
                <div style={{marginTop:'-26px'}}>
                <Contact/>
                </div>
                
            </div>
        )
    }
}

export default ContactPage
