import React,  { Component } from "react";
import { withRouter } from "../helpers/withRouter";

class Services extends Component {
  service (){
    this.props.navigate("/offerings")
  }
 render(){
  return (
    <div id="SERVICES" className="services-component">
     <div className="services-content">
        <h1 className="serviceHeader">Offerings</h1>
        <div className="titles">
        <li className="list">Digital Transformation</li>
        <li className="list">Smart Analysis</li>
        <li className="list">Cloud & Hybrid IT Management</li>
        <li className="list">Security Services</li>
        </div>
        <img src="images/service/offering.png" alt="" className="offering-page logo"
        width="50%"
        data-aos="fade-right"
        data-aos-delay="500"
        data-aos-duration="1000"/>
        <div  data-aos="fade-right"
        data-aos-delay="500"
        data-aos-duration="1000"
        className="readm"
        >
          <button className="readmore-service" onClick={() => this.service()}>Read More</button>
        </div>
      </div>
    </div>
  );
  }
}

export default withRouter(Services);
