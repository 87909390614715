import React, { Component } from 'react'

class Blog extends Component {
    
    componentDidUpdate(prevProps){
        if(prevProps.blog !== this.props.blog){
            this.forceUpdate()
        }
    }
    
    render() {
        return (     
            <div className="blog2">
            { this.props.blog.length !== 0 && this.props.blog.map(b => {
                return (
                 <div  key={b.id} className="content" style={{width:'30%'}}>
                    <div className="box">
                    <img src={"http://pgi-data.com/data/images/"+b.image} className="image-cs" alt="" />
                    </div>
                    <div className="boxyellow">
                        <span className="heading">{b.title}</span>
                        <span className="subheading">{b.subtitle}</span>
                        <span className="content">{b.shortdesc}
                        </span>
                        <strong style={{textAlign: 'center', paddingBottom: '30px'}}><a href={b.file !== '' ? "https://pgi-data.com/data/download?filename="+b.file : b.url}
                        style={{cursor:'pointer', color: '#002370'}}>Read On</a></strong>
                    </div>
                </div>
              )
            })}
           </div>
          )  
  }
}

export default Blog
