import React, { Component } from 'react'
import Header from './header'
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

function importAll2(r) {
    let images = [];
    r.keys().map((item, index) => {
        return images.push(item.replace('./', 'images/offerings/key/'))
    });
    return images;
}

const tech2 = importAll2(require.context('../../public/images/offerings/key', false, /\.(png|jpe?g|svg)$/));

export class SmartPage extends Component {
    state = {
        active: '00',
        slideImg: ['images/analys-data-right-hand.png']
    }
    render() {
        const settingsTech = {
            dots: false,
            infinite: true,
            autoplay: true,
            speed: 2000,
            autoplaySpeed: 1000,
            slidesToShow: 4,
            slidesToScroll: 1,
            arrows: false,
            responsive: [
                {
                    breakpoint: 1024,
                    settings: {
                        slidesToShow: 3,
                        slidesToScroll: 3,
                        infinite: true,
                    }
                },
                {
                    breakpoint: 790,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 2,
                        initialSlide: 2
                    }
                },
                {
                    breakpoint: 480,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1
                    }
                }
            ]
        };
        return (
            <div>
                <Header/>
                <div className="smart">
                  <h3 className="title">SMART ANALYSIS​</h3>
                  <div className="box-container">
                  <div className="box">
                      <div>
                          <img src="images/offerings/5.png" width="100px" alt=""/>
                      </div>
                      <div className="text">
                      DATA​ INTEGRATION
                      </div>
                      <div className="text2">
                      Digitalization, management​ And consolidation​ of the data sources
                      </div>
                  </div>
                  <div className="box">
                      <div>
                          <img src="images/offerings/6.png" width="100px" alt=""/>
                      </div>
                      <div className="text">
                      BI & DATA​ VISUALIZATION
                      </div>
                      <div className="text2">
                      Business Intelligence, Data​ Visualization, Reporting &​ Dashboards, Scorecard
                      </div>
                  </div>
                  <div className="box">
                      <div style={{display:"flex", justifyContent: 'center'}}>
                          <img src="images/offerings/7.png" width="100px" alt=""/>
                      </div>
                      <div className="text">
                      DATA​ HARMONISATION​ & CLEANING
                      </div>
                      <div className="text2">
                      Ensure data assessment & portfolio health check. Data migration, quality check/validation
                      </div>
                  </div>
                  <div className="box">
                      <div>
                          <img src="images/offerings/8.png" width="100px" alt=""/>
                      </div>
                      <div className="text">
                      DATA​ WAREHOUSE
                      </div>
                      <div className="text2">
                      Extract Transfer & Load,​ Data Staging, Data Model
                      </div>
                  </div>
                  <div className="box">
                      <div>
                          <img src="images/offerings/9.png" width="100px" alt=""/>
                      </div>
                      <div className="text">
                      PREDICTIVE​ ANALYSIS
                      </div>
                      <div className="text2">
                      Big Data Diagnostics,​ Descriptive, Prescriptive &​ Predictive Analytics,​ Forecasting Models
                      </div>
                  </div>
                  <div className="box">
                      <div>
                          <img src="images/offerings/10.png" width="100px" alt=""/>
                      </div>
                      <div className="text">
                      STRATEGY &​ CONSULTATION
                      </div>
                      <div className="text2">
                      Strategic decisions,​ consultation,​ business plan of action.
                      </div>
                  </div>
                  </div>
                </div>
                <div className="client-container">
                    <span className="title-tech" style={{fontSize:'35pt'}}>
                       KEY TECHNOLOGIES
                            </span>
                    <Slider {...settingsTech}>
                        {tech2.map((image, i) => {
                            return <img src={image} alt="" className="image-slide" />
                        })}
                    </Slider>
                </div>
            </div>
        )
    }
}

export default SmartPage
