import React, { Component } from 'react'
import Header from './header'
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

function importAll2(r) {
    let images = [];
    r.keys().map((item, index) => {
        return images.push(item.replace('./', 'images/offerings/key3/'))
    });
    return images;
}

const tech2 = importAll2(require.context('../../public/images/offerings/key3', false, /\.(png|jpe?g|svg)$/));

export class CloudPage extends Component {
    state = {
        active: '00',
        slideImg: ['images/analys-data-right-hand.png']
    }
    render() {
        const settingsTech = {
            dots: false,
            infinite: true,
            autoplay: true,
            speed: 2000,
            autoplaySpeed: 1000,
            slidesToShow: 4,
            slidesToScroll: 1,
            arrows: false,
            responsive: [
                {
                    breakpoint: 1024,
                    settings: {
                        slidesToShow: 3,
                        slidesToScroll: 3,
                        infinite: true,
                    }
                },
                {
                    breakpoint: 790,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 2,
                        initialSlide: 2
                    }
                },
                {
                    breakpoint: 480,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1
                    }
                }
            ]
        };
        return (
            <div>
                <Header/>
                <div className="cloud">
                  <h3 className="title">CLOUD & HYBRID IT MANAGEMENT​</h3>
                  <div className="box-container">
                  <div className="box">
                      <div>
                          <img src="images/offerings/11.png" width="100px" alt=""/>
                      </div>
                      <div className="text">
                      Build – ​hybrid/private​ Cloud
                      </div>
                      <div className="text2">
                      VMWare, OpenStack or Open-Shift based private cloud, Support containerized workloads, elastic scaling, cost metering​
                      <br/><br/>Hybrid workload design and migration strategy (from physical to virtual)
                      </div>
                  </div>
                  <div className="box">
                      <div>
                          <img src="images/offerings/12.png" width="100px" alt=""/>
                      </div>
                      <div className="text">
                      Migrate to public/ private/hybrid Cloud
                      </div>
                      <div className="text2">
                      Application migration – physical to virtual, AWS, Azure or public cloud. Re-architecture and infra design ​
                      <br/><br/>Professional services for cloud-native development​
                      </div>
                  </div>
                  <div className="box">
                      <div>
                          <img src="images/offerings/13.png" width="100px" alt=""/>
                      </div>
                      <div className="text">
                      Hybrid IT​<br/>Operations Management​
                      </div>
                      <div className="text2">
                      Application ops​ L1/L2 business operations​ Application configuration changes​ Kpis, reporting​
                      <br/><br/>Systems ops​ OS (unix, windows) management​ Middleware ops management​ Database management​ Storage operations​ Network management
                      </div>
                  </div>
                  <div className="box">
                      <div>
                          <img src="images/offerings/14.png" width="100px" alt=""/>
                      </div>
                      <div className="text">
                      AIOps and Service Management (ITOM/ITSM) 
                      </div>
                      <div className="text2">
                      Transform ITSM experience with servicenow – ITSM, ITOM, CMDB, event mgmt.​ It operations management – open-source monitoring architecture – monitoring, log analytics, performance management, capacity, it ops management, siem
                      </div>
                  </div>
                  <div className="box">
                      <div>
                          <img src="images/offerings/15.png" width="100px" alt=""/>
                      </div>
                      <div className="text">
                      Intelligent​ Process​ Automation
                      </div>
                      <div className="text2">
                      Transform AI/ML led process automation with UIPath​ IT cloud operations automation – build, compliance, Day-2 ops​ Event automation, RPA led business process automation
                      </div>
                  </div>           
                  </div>
                </div>
                <div className="client-container">
                    <span className="title-tech" style={{fontSize:'35pt'}}>
                        KEY TECHNOLOGIES
                            </span>
                    <Slider {...settingsTech}>
                        {tech2.map((image, i) => {
                            return <img src={image} alt="" className="image-slide" />
                        })}
                    </Slider>
                </div>
            </div>
        )
    }
}

export default CloudPage
