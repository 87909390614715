import { put, takeEvery, call } from "redux-saga/effects";
import { postUserJob } from "../actions/data";
import axios from "axios";

export function userJobAPI(payload) {
  const apiUrl = process.env.REACT_APP_API_URL
  const url = apiUrl + "data/upload";
  const data = new FormData()
  data.append('file', payload.file, payload.file.name);
  data.append('firstname', payload.firstname);
  data.append('lastname', payload.lastname);
  data.append('email', payload.email);
  data.append('phone', payload.phone);
  data.append('message', payload.message);
  data.append('job_id', payload.job_id);
  
  const headers = { 'accept': "*/*",
                "Accept-Language": "en-US,en;q=0.8",
                "Content-Type": `multipart/form-data;`
  }
  return axios.post(url,data,headers);
}

export function* postUserJobRequest(action) {
  try {
    const response = yield call(userJobAPI, action.payload);

    if (response) {
      yield put(
        postUserJob.success({
          data: response.data,
          meta: response.data.meta,
        })
      );
    }
  } catch (error) {
    yield put(postUserJob.failure(error.message));
  }
}

export default function* postUserJobSagapostUserJobSaga() {
  yield takeEvery(postUserJob.REQUEST, postUserJobRequest);
}