import React, { Component } from 'react'
import Header from './header'
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

function importAll2(r) {
    let images = [];
    r.keys().map((item, index) => {
        return images.push(item.replace('./', 'images/offerings/key4/'))
    });
    return images;
}

const tech2 = importAll2(require.context('../../public/images/offerings/key4', false, /\.(png|jpe?g|svg)$/));

export class SecurityPage extends Component {
    state = {
        active: '00',
        slideImg: ['images/analys-data-right-hand.png']
    }
    render() {
        const settingsTech = {
            dots: false,
            infinite: true,
            autoplay: true,
            speed: 2000,
            autoplaySpeed: 1000,
            slidesToShow: 4,
            slidesToScroll: 1,
            arrows: false,
            responsive: [
                {
                    breakpoint: 1256,
                    settings: {
                        slidesToShow: 3,
                        slidesToScroll: 3,
                        infinite: true,
                    }
                },
                {
                    breakpoint: 790,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 2,
                        initialSlide: 2
                    }
                },
                {
                    breakpoint: 480,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1
                    }
                }
            ]
        };
        return (
            <div>
                <Header/>
                <div className="security">
                  <h3 className="title">SECURITY SERVICES​</h3>
                  <div className="box-container">
                  <div className="box">
                      <div>
                          <img src="images/offerings/16.png" width="100px" alt=""/>
                      </div>
                      <div className="text">
                      SYSTEM​ SECURITY
                      </div>
                      <div className="text2">
                      Minimum baseline​ security (MBSS),​ password policy,​ server hardening
                      </div>
                  </div>
                  <div className="box">
                      <div>
                          <img src="images/offerings/17.png" width="100px" alt=""/>
                      </div>
                      <div className="text">
                      ENDPOINT​ SECURITY
                      </div>
                      <div className="text2">
                      Ensure client systems are deployed, optimally configured and secure
                      </div>
                  </div>
                  <div className="box">
                      <div>
                          <img src="images/offerings/18.png" width="100px" alt=""/>
                      </div>
                      <div className="text">
                      PRIVILEDGE ID​ MANAGEMENT (PIM)
                      </div>
                      <div className="text2">
                      PIM for OS, DB, Admin​ application IDs,​ ID monitoring,​ unauthorized access control
                      </div>
                  </div>
                  <div className="box">
                      <div>
                          <img src="images/offerings/19.png" width="100px" alt=""/>
                      </div>
                      <div className="text">
                      IDENTITY & ACCESS​ MANAGEMENT
                      </div>
                      <div className="text2">
                      Application & privilege ID management, quarterly review/validation, password management
                      </div>
                  </div>
                  <div className="box">
                      <div>
                          <img src="images/offerings/20.png" width="100px" alt=""/>
                      </div>
                      <div className="text">
                      NETWORK​ SECURITY​
                      </div>
                      <div className="text2">
                      Firewall security scanner,​ DNS, Ddos protection,​ firewall rule – risk​ assessment, Port VA
                      </div>
                  </div>        
                  <div className="box">
                      <div>
                          <img src="images/offerings/21.png" width="100px" alt=""/>
                      </div>
                      <div className="text">
                      SIEM
                      </div>
                      <div className="text2">
                      Security analytics, log​ management,​ alerting, advanced​ threat management.
                      </div>
                  </div>     
                  </div>
                </div>
                <div className="client-container" style={{width:'78%', margin: '0 auto'}}>
                    <span className="title-tech" style={{fontSize:'35pt'}}>
                        KEY TECHNOLOGIES
                            </span>
                    <Slider {...settingsTech}>
                        {tech2.map((image, i) => {
                            return <img src={image} alt="" className="image-slide" />
                        })}
                    </Slider>
                </div>
            </div>
        )
    }
}

export default SecurityPage
