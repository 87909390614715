import React, { Component } from "react";

class SolutionService extends Component {

  render() {
    return (
      <div id={'section1'} className="solution-component-service">
        <div className="solution">
          <h1 className="init" style={{ textAlign: 'center', fontSize: "45pt"}}>SOLUTION SPACE</h1>
           <div className="text-solution2" >
            Our Data Science expertise has helped build customer acquisition & retention, customer lifetime value, customer credit risk, credit rating, demand forecasting, supply chain and logistics management, pricing and offer optimization.
            <br></br>
            <br></br>
          PGI Data has worked for multiple industries to improve customer loyalty, minimize operational cost, improve customer satisfaction and maximize profitability.
        </div>

        <div style={{ paddingTop: '30px' }}>
          <table>
            <tbody>
            <tr>
              <td className="tdImage">
                <img src="images/solution-space/solution2.png" className="image-solution" alt="" />
              </td>
              <td className="tdText">
              <h2 style={{ textAlign: 'left', fontSize: "16pt", color: "#FFCC00" }}>RETAIL & FMCG</h2>
                <div className="text-solution2" >
                  At every stage of the journey from manufacturing to
                  delivery, a company faces several challenges in decision
                  making and the only resource that can be relied upon is
                  data. With the help of advanced machine learning
                  algorithms, the Retail & FMCG companies can merge all
                  the data and bring structure to it, and therefore find
                  better answers to various problems that plague their
                  business
                </div>
                <td>
                  {/* isi */}
                </td>
              </td>
            </tr>
            <tr>
              <td>
                 {/* isi */}
              </td>
              <td className="tdText">
                <h2 style={{ textAlign: 'left', fontSize: "16pt", color: "#FFCC00" }}>BANKING & INSURANCE</h2>
                <div className="text-solution2" >
                  Finance sector is probably the most data-intensive
                  sector in the global economy. They automate risk using
                  big data analytics and machine learning techniques to
                  identify, monitor and prioritize the risks for all channels
                  and carry out strategic decisions. They also strategize
                  Customer Relationship to segment the bank’s most
                  lucrative prospects and expand the relationship.
                </div>
              </td>
              <td className="tdImage">
                  <img src="images/solution-space/solution3.png" className="image-solution" alt="" />
                </td>
            </tr>
            <tr>
              <td className="tdImage">
                <img src="images/solution-space/solution4.png" className="image-solution" alt="" />
              </td>
              <td className="tdText">
              <h2 style={{ textAlign: 'left', fontSize: "16pt", color: "#FFCC00" }}>TELECOMMUNICATION</h2>
                <div className="text-solution2" >
                Within the telecom industry Big data architecture and
                data science applications are widely used to streamline
                the operations, to maximize profits, to build effective
                marketing and business strategies, to visualize data, to
                perform data transfer and channelize products from
                retailers to consumers effectively. They continuously
                interact with their customers and interpret their usage
                patterns to offer them tailor-made plans to fill this
                intrinsic need.
                </div>
                <td>
                  {/* isi */}
                </td>
              </td>
            </tr>
            <tr>
              <td>
                 {/* isi */}
              </td>
              <td className="tdText">
              <h2 style={{ textAlign: 'left', fontSize: "16pt", color: "#FFCC00" }}>HEALTHCARE</h2>
                <div className="text-solution2" style={{    marginBottom: '100px'}}>
                Healthcare Industry leverages extensive analytics to
                realize their full potential in using fact-based
                forecasting techniques based on the market potential.
                They consume the data from physician attitude, sample
                optimization, performance measurement and
                transform data digitally to improve sales force
                motivation and performance through effective
                incentive compensation and target planning.
                </div>
              </td>
              <td className="tdImage">
                  <img src="images/solution-space/solution5.png" className="image-solution" alt="" />
                </td>
            </tr>
            </tbody>
          </table>
        </div>



      <div className="showphone">
          <div style={{ paddingTop: '30px' }}>
            <div className="flex">
              <img src="images/solution-space/solution2.png" className="image-solution" alt="" />
              <div className="textWrap">
                <h2 style={{ textAlign: 'left', fontSize: "16pt", color: "#FFCC00" }}>RETAIL & FMCG</h2>
                <div className="text-solution2" >
                  At every stage of the journey from manufacturing to
                  delivery, a company faces several challenges in decision
                  making and the only resource that can be relied upon is
                  data. With the help of advanced machine learning
                  algorithms, the Retail & FMCG companies can merge all
                  the data and bring structure to it, and therefore find
                  better answers to various problems that plague their
                  business
                </div>
              </div>
            </div>
          </div>
          <div style={{ paddingTop: '30px' }}>
            <div className="flexR">
              <img src="images/solution-space/solution3.png" className="image-solution" alt="" />
              <div className="textWrap2">
                <h2 style={{ textAlign: 'left', fontSize: "16pt", color: "#FFCC00" }}>BANKING & INSURANCE</h2>
                <div className="text-solution2" >
                Finance sector is probably the most data-intensive
sector in the global economy. They automate risk using
big data analytics and machine learning techniques to
identify, monitor and prioritize the risks for all channels
and carry out strategic decisions. They also strategize
Customer Relationship to segment the bank’s most
lucrative prospects and expand the relationship.
                </div>
              </div>
            </div>
          </div>
          <div style={{ paddingTop: '30px' }}>
            <div className="flex">
              <img src="images/solution-space/solution4.png" className="image-solution" alt="" />
              <div className="textWrap">
                <h2 style={{ textAlign: 'left', fontSize: "16pt", color: "#FFCC00" }}>TELECOMMUNICATION</h2>
                <div className="text-solution2" >
                Within the telecom industry Big data architecture and
data science applications are widely used to streamline
the operations, to maximize profits, to build effective
marketing and business strategies, to visualize data, to
perform data transfer and channelize products from
retailers to consumers effectively. They continuously
interact with their customers and interpret their usage
patterns to offer them tailor-made plans to fill this
intrinsic need.
                </div>
              </div>
            </div>
          </div>
          <div style={{ paddingTop: '30px' }}>
            <div className="flexR">
              <img src="images/solution-space/solution5.png" className="image-solution" alt="" />
              <div className="textWrap2">
                <h2 style={{ textAlign: 'left', fontSize: "16pt", color: "#FFCC00" }}>HEALTHCARE</h2>
                <div className="text-solution2" style={{    marginBottom: '100px'}}>
                Healthcare Industry leverages extensive analytics to
                realize their full potential in using fact-based
                forecasting techniques based on the market potential.
                They consume the data from physician attitude, sample
                optimization, performance measurement and
                transform data digitally to improve sales force
                motivation and performance through effective
                incentive compensation and target planning.

                </div>
              </div>
            </div>
          </div>
          </div>
        </div>
        
      </div>
    );
  }
}

export default SolutionService;
