import React, { Component } from "react";
import { TextField } from "@mui/material";

class Contact extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      email: "",
      phone: "",
      coment: "",
      phonerr: false,
      emailerr: false,
      nameError: '',
      emailError: undefined,
      phoneError: undefined,
      disabled: true,
      success: false
    };
  }

  linkedin() {
    window.open("https://www.linkedin.com/company/pgi-data/");
  };
  twitter() {
    window.open("https://twitter.com/GlobalPgi");
  };
  whatsapp() {
    window.open("https://wa.link/n15ins")
  };
  handleChangeEmail = event => {
    this.setState({ email: event.target.value });
    if (this.state.email !== null) {
      if (this.state.email.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{1,})$/)) {
        this.setState({ emailError: undefined, disabled: false, emailerr: false });
      } else {
        this.setState({
          emailError: "Please enter a valid email address.",
          disabled: true,
          emailerr: true
        });
      }
    }
  };

  handleChangePhone = event => {
    this.setState({ phone: event.target.value });
    if (this.state.phone !== null) {
      if ((this.state.phone.length >= 9) && (this.state.phone.length <= 11)) {
        this.setState({ phoneError: undefined, disabled: false, phonerr: false });
      } else {
        this.setState({
          phoneError: "Please Enter a Valid Phone Number",
          disabled: true,
          phonerr: true
        });
      }
    }
  };
  handleChangeName = event => {
    this.setState({ name: event.target.value });
  };
  handleChangeComment = event => {
    this.setState({ coment: event.target.value });
  };

  handleSubmit = () => {
    if (this.state.name === '' || this.state.coment === '' || this.state.email === '' || this.state.phone === '' ) {
      console.log("fail");
      this.setState({ success: false });
    }else{
      console.log("success");
      this.setState({ success: true });
    }
  };



  render() {
    return (
      <div className="contact-component">
        <div className="contact">
          <h1 className="contact-title">Contact us</h1>
          <div className="social">
            <button className="s" onClick={() => this.twitter()}>
              <i className="fa fa-twitter" style={{ fontSize: "22px", marginRight: "10px" }}></i>
              twitter
            </button>
            {/* <button className="s" onClick={() => this.whatsapp()}>
              <img className="fas" src="images/2.png" alt="" style={{ width: '18px', marginRight: "10px" }} />
            WhatsApp
            </button> */}
            <button className="s" onClick={() => this.linkedin()}>
              <i className="fa fa-linkedin" style={{ fontSize: "22px", marginRight: "10px" }}></i>
            LinkedIn
            </button>
          </div>
          <div className="social2">
            <ul className="info">
              <li style={{ listStyleImage: "url(images/5.png)" }}><a className="emailto" href="mailto:info@pgi-data.com"><span>info@pgi-data.com</span></a></li>  
              <li style={{ listStyleImage: "url(images/7.png)" }}><span> Menara Anugrah Building 16th Floor <br/>
              Kantor Taman E 3.3, Jl. Dr. Ide Anak Agung Gde Agung Lot. 8.6-8.7 <br/>
              Kawasan Mega Kuningan, Kuningan Timur, Setiabudi, <br/> Jakarta Selatan 12950</span></li>            
              <li style={{ listStyleImage: "url(images/7.png)" }}><span>6 Raffles Quay #11-07 Singapore 048580</span></li>
            </ul>
          </div>
          <img
            src="images/indonesia.png" alt=""
            className="contact-img" />
          <div >


          </div>
        </div>
        <div className="form">
          <form>
            <TextField
              value={this.state.name}
              onChange={event => this.handleChangeName(event)}
              className="form1"
              type="text" id="fname"
              name="firstname"
              placeholder="Name"
              variant="outlined" />
            <TextField
              error={this.state.emailerr}
              className="form1"
              type="text"
              id="mail"
              value={this.state.email}
              helperText={this.state.emailError}
              onChange={event => this.handleChangeEmail(event)}
              name="E-mail"
              placeholder="Email"
              variant="outlined" />

            <TextField
              error={this.state.phonerr}
              value={this.state.phone}
              helperText={this.state.phoneError}
              onChange={event => this.handleChangePhone(event)}
              className="form1"
              type="number"
              id="phone"
              name="Phone"
              placeholder="Phone"
              variant="outlined"
            />
            <TextField
              value={this.state.coment}
              onChange={event => this.handleChangeComment(event)}
              multiline={true}
              rows={5}
              className="form2"
              type="text"
              id="Message"
              name="Message"
              placeholder="Message"
              variant="outlined"
            />
            <button onClick={() => this.handleSubmit()} type="button" value="Check" className="send">SEND</button>
            {this.state.success=== true &&
            <div className="alert">
            <p className="text-alert">“Thank you for your response. We will get back to you soon”</p>
          </div>}
            
          </form>

        </div>
      </div>
    );
  }
}

export default Contact;
