import React, { Component } from 'react'
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import './styles.scss';
import SolutionService from './Solution-space-service';
import Header from './header';
import { withRouter } from '../helpers/withRouter'; 

function importAll(r) {
    let images = [];
    r.keys().map((item, index) => {
        return images.push(item.replace('./', 'images/offerings/partner/'))
    });
    return images;
}
function importAll2(r) {
    let images = [];
    r.keys().map((item, index) => {
        return images.push(item.replace('./', 'images/clients/tech/'))
    });
    return images;
}
function importAll3(r) {
    let images = [];
    r.keys().map((item, index) => {
        return images.push(item.replace('./', 'images/offerings/client/'))
    });
    return images;
}

const tech = importAll(require.context('../../public/images/offerings/partner/', false, /\.(png|jpe?g|svg)$/));
const tech2 = importAll2(require.context('../../public/images/clients/tech/', false, /\.(png|jpe?g|svg)$/));
const tech3 = importAll3(require.context('../../public/images/offerings/client/', false, /\.(png|jpe?g|svg)$/));
class ServicePage extends Component {
    state = {
        active: '00',
        slideImg: ['images/analys-data-right-hand.png']
    }
    digitrans(){
        this.props.navigate("/digitrans")
    }
    smart(){
        this.props.navigate("/smartanalytics")
    }
    cloud(){
        this.props.navigate("/cloud")
    }
    security(){
        this.props.navigate("/security")
    }
    render() {
        const settingsTech = {
            dots: false,
            infinite: true,
            autoplay: true,
            speed: 2000,
            autoplaySpeed: 1000,
            slidesToShow: 4,
            slidesToScroll: 1,
            arrows: false,
            responsive: [
                {
                    breakpoint: 1024,
                    settings: {
                        slidesToShow: 3,
                        slidesToScroll: 3,
                        infinite: true,
                    }
                },
                {
                    breakpoint: 790,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 2,
                        initialSlide: 2
                    }
                },
                {
                    breakpoint: 480,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1
                    }
                }
            ]
        };
        return (
            <div>
                <Header />
                <div id="Services" className="service-page">
                    <div className="content">
                        <h1 className="titled">
                        OUR OFFERINGS
                        </h1>
                        {/* <div className="flexRow">
                            <img src="images/service/service.png" className="imageServices" alt="" />
                            <div className="serviceSub">
                                We combine the resources of Statistics, Advanced Analytics, Big Data Services, and Data Engineering to drive business decisions for digital undertakings.
                            <br></br><br></br>We explore all possible data points, draw insights from the raw data and prescribe the best practices in business operations, enabling quicker and progressively critical decision making to all organizations, thus developing strategic partnerships with our customers rather than just solutions in silos

                            </div>
                        </div> */}
                        <div style={{display:'flex'}}>
                        <div className="box-cont">
                         <div className="box" >
                            <div className="flexRow between">
                                <div className="contentBox">
                                    <h3 className="titleBox">Digital Transformation​</h3>
                                    <div className="subtitleBox">
                                        <li>Customer Experience (CX) advisory​</li>
                                        <li>Application Development: Custom Apps Mobile & Web, IoT</li>
                                        <li>Digital Smarts (Dashboards and Omnichannel Chatbots)​</li>
                                    </div>
                                </div>
                                <div onClick={() => this.digitrans()} className="readmore">Read More</div>
                            </div> 
                        </div>
                        <div className="box" >
                            <div className="flexRow between">
                                <div className="contentBox">
                                    <h3 className="titleBox">Smart Analytics​​</h3>
                                    <div className="subtitleBox">
                                        <li>Data harmonization & cleaning</li>
                                        <li>Data integration & Data Warehousing</li>
                                        <li>BI & Data Visualization, AI/ML led predictive analysis​​</li>
                                    </div>
                                </div>
                                <div onClick={() => this.smart()} className="readmore">Read More</div>
                            </div> 
                        </div>
                        <div className="box" style={{backgroundColor: '#AAABAC'}}>
                            <div className="flexRow between">
                                <div className="contentBox">
                                    <h3 className="titleBox" style={{backgroundColor: '#AAABAC', color:'white'}}>Cloud & Hybrid IT management​</h3>
                                    <div className="subtitleBox">
                                        <li>Build and Migrate hybrid/ private cloud</li>
                                        <li>AI Ops & Hybrid IT Service Management</li>
                                        <li>Intelligent process automation</li>
                                    </div>
                                </div>
                                <div onClick={() => this.cloud()} className="readmore" style={{backgroundColor: '#AAABAC', color:'white'}}>Read More</div>
                            </div> 
                        </div>
                        <div className="box" style={{backgroundColor: '#AAABAC'}} >
                            <div className="flexRow between">
                                <div className="contentBox">
                                    <h3 className="titleBox" style={{backgroundColor: '#AAABAC', color:'white'}}>​Security Services</h3>
                                    <div className="subtitleBox">
                                        <li>Network security​</li>
                                        <li>Security Incident and Events management</li>
                                        <li>Identity and Access management solution</li>
                                    </div>
                                </div>
                                <div onClick={() => this.security()} className="readmore" style={{backgroundColor: '#AAABAC', color:'white'}}>Read More</div>
                            </div> 
                        </div>
                      </div>
                      <div className="img-offer">
                        <img src="images/service/offering.png" width="171%" alt=""/>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="client-container">
                    <span className="title-tech" >
                        Clients
                            </span>
                    <Slider {...settingsTech}>
                        {tech3.map((image, i) => {
                            return <img src={image} alt="" className="image-slide" />
                        })}
                    </Slider>
                </div>
                <div className="client-container">
                    <span className="title-tech" >
                        Our Partnerships
                            </span>
                    <Slider {...settingsTech}>
                        {tech.map((image, i) => {
                            return <img src={image} alt="" className="image-slide" />
                        })}
                    </Slider>
                </div>
                <div className="client-container">
                    <span className="title-tech" >
                        Technology
                            </span>
                    <Slider {...settingsTech}>
                        {tech2.map((image, i) => {
                            return <img src={image} alt="" className="image-slide" />
                        })}
                    </Slider>
                </div>   
                <SolutionService />
            </div>
        )
    }
}

export default withRouter(ServicePage)
