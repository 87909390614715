import { put, takeEvery, call } from "redux-saga/effects";
import { postJob } from "../actions/data";
import axios from "axios";

export function jobAPI(payload) {
  const apiUrl = process.env.REACT_APP_API_URL
  const url = apiUrl + "data/job";
  const data = {
    location: payload.location,
    experience: payload.experience,
    jobdesc: payload.jobdesc,
    desc: payload.desc
  };
  return axios.post(url,data);
}

export function* postJobRequest(action) {
  try {
    const response = yield call(jobAPI, action.payload);

    if (response) {
      yield put(
        postJob.success({
          data: response.data,
          meta: response.data.meta,
        })
      );
    }
  } catch (error) {
    yield put(postJob.failure(error.message));
  }
}

export default function* postJobSaga() {
  yield takeEvery(postJob.REQUEST, postJobRequest);
}