
import { put, takeEvery, call } from "redux-saga/effects";
import { getUserJob} from "../actions/data";
import axios from "axios";

export function getUserJobAPI(payload) {
  const apiUrl = process.env.REACT_APP_API_URL
  const url = apiUrl + "data/list-user";
  return axios.get(url);
}

export function* userJobRequest(action) {
  try {
    const response = yield call(getUserJobAPI, action.payload);

    if (response) {
      yield put(
        getUserJob.success({
          data: response.data.data
        })
      );
    }
  } catch (error) {
    yield put(getUserJob.failure(error.message));
  }
}

export default function* getUserJobSaga() {
  yield takeEvery(getUserJob.REQUEST, userJobRequest);
}
