
import { put, takeEvery, call } from "redux-saga/effects";
import { deleteInsight} from "../actions/data";
import axios from "axios";

export function deleteInsightAPI(payload) {
  const apiUrl = process.env.REACT_APP_API_URL
  const url = apiUrl + "data/insight";
  const data = {
    id: payload.id
  };
  return axios.delete(url,{data});
}

export function* insightRequest(action) {
  try {
    const response = yield call(deleteInsightAPI, action.payload);

    if (response) {
      yield put(
        deleteInsight.success({
          data: Math.random()
        })
      );
    }
  } catch (error) {
    yield put(deleteInsight.failure(error.message));
  }
}

export default function* deleteInsightSaga() {
  yield takeEvery(deleteInsight.REQUEST, insightRequest);
}
