import React, { Component } from "react";
import Slider from "react-slick";
import Footer from "./footer";
import Services from "./Services";
import Insight from "./insight";
import Solution from "./Solution-space";
import Contact from "./Contact";
import './styles.scss';
import Header from "./header";  
import { withRouter } from "../helpers/withRouter";

function importAll(r) {
  let images = [];
  r.keys().map((item, index) => {
    return images.push(item.replace('./', 'images/offerings/client/'))
  });
  return images;
}

function importAll2(r) {
  let images = [];
  r.keys().map((item, index) => {
    return images.push(item.replace('./', 'images/clients/tech/'))
  });
  return images;
}
function importAll3(r) {
  let images = [];
  r.keys().map((item, index) => {
    return images.push(item.replace('./', 'images/carousel/'))
  });
  return images;
}

function importAll4(r) {
  let images = [];
  r.keys().map((item, index) => {
    return images.push(item.replace('./', 'images/offerings/partner/'))
  });
  return images;
}

const clients = importAll(require.context('../../public/images/offerings/client/', false, /\.(png|jpe?g|svg)$/));
const tech = importAll2(require.context('../../public/images/clients/tech/', false, /\.(png|jpe?g|svg)$/));
const carousel = importAll3(require.context('../../public/images/carousel/', false, /\.(png|jpe?g|svg)$/));
const partner = importAll4(require.context('../../public/images/offerings/partner/', false, /\.(png|jpe?g|svg)$/));

class Home extends Component {
  state = {
    active: '00',
    menu: ['HOME', 'ABOUT', 'SERVICES', 'INSIGHT', 'CAREER', 'CONTACT'],
    slideImg: ['images/analys-data-right-hand.png'],
    type: 1,
  }

  showSettings(event) {
    event.preventDefault();
  }
  
  about (){
    this.props.navigate("/about")
  };
  analytic(){
    this.setState({type: 2});
  } ;
  analytic2(){
    this.setState({type: 1});
  };

  render() {
   

    const settingsClient = {
      dots: false,
      infinite: true,
      loop:true,
      autoplay: true,
      speed: 2500,
      autoplaySpeed: 2000,
      slidesToShow: 4,
      slidesToScroll: 1,
      focusOnSelect: false,
      arrows: false,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
            
          }
        },
        {
          breakpoint: 790,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
            initialSlide: 2,
           
          }
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
           
          }
        }
      ]
    
    };
    const settingsBanner = {
      dots: false,
      infinite: true,
      autoplay: true,
      speed: 2000,
      autoplaySpeed: 2000,
      slidesToShow: 1,
      slidesToScroll: 1,
      arrows: false
  };
    const settingsTech = {
      dots: false,
      focusOnSelect: false,
      infinite: true,
      autoplay: true,
      speed: 2000,
      autoplaySpeed: 2000,
      slidesToShow: 4,
      slidesToScroll: 1,
      arrows: false,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 1,
           
          }
        },
        {
          breakpoint: 790,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
            initialSlide: 2,
            
          }
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
           
          }
        }
      ]
    };

    return (
      <div className="App">
        <Header/>
        <div className="menu-section">
          <div style={{ borderTop: 'none' }}>
            <Slider className="slider" {...settingsBanner}>
              {carousel.map((image, i) => {
                return <img src={image} alt="" className="carousel-slide" />
              })}
            </Slider>
          </div>
          {/* <div id="Home" className="home-component">
            <Player
              src="video/50_ res video.mp4"
              autoPlay
              loop
              muted
              width={100}
            >
              <LoadingSpinner />
              <ControlBar disabled />
              <BigPlayButton disabled />
            </Player>
          </div> */}
          <div className="about" id="ABOUT">
            <div style={{width:"66em", height: '100%', margin: 'auto', display: 'flex',flexDirection: 'row'}}>
            <div className="content-wrap">
              <div>
                <div>
                  <img src="images/logo-pgi.png" className="logo-big" alt="" />  
                </div>
                <div>
                  <h2 className="about-title">Founded</h2>
                  <p className="about-subtitle">
                    2018 in Jakarta, Indonesia
                </p>
                </div>
                <div>
                  <h2 className="about-title">Principle</h2>
                  <p className="about-subtitle">
                    PGI Data believes in order to succeed 
                    in this rapidly evolving economy, companies 
                    from all domains must use <strong>Big Data,
                    Data Science , Machine Learning, 
                    and Artificial Intelligence.</strong> 
                  </p>
                </div>
                <h2 className="about-title">Mission</h2>
                <div className="flex-rows">
                  <div>
                    <div className="flex-row">
                      <h1 className="number">
                        1
                    </h1>
                      <div className="text left-40">
                        <h3 className="about-subtitle2" style={{ fontWeight: "bold" }}>
                          Envision
                      </h3>
                        <p className="about-subtitle2">
                          To channelize companies in the right direction through their data
                      </p>
                      </div>
                    </div>
                  </div>
                  <div>
                    <div className="flex-row">
                      <h1 className="number">
                        2
                    </h1>
                      <div className="text2 left-40">
                        <h3 className="about-subtitle2" style={{ fontWeight: "bold" }}>
                          Explore
                      </h3>
                        <p className="about-subtitle2">
                          The data captured in depth
                      </p>
                      </div>
                    </div>
                  </div>
                  <div>
                    <div className="flex-row">
                      <h1 className="number">
                        3
                    </h1>
                      <div className="text2 left-40">
                        <h3 className="about-subtitle2" style={{ fontWeight: "bold" }}>
                          Execute
                      </h3>
                        <p className="about-subtitle2">
                          Innovative data science concepts in their data to get insightful conclusions
                      </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div>
                    <button className="readmore" onClick={() => this.about()} >Read More</button>
                  </div>
              </div>
            </div>
            <div className="analytic left-40" >

            </div>
            </div>
          </div>





          {/* <WhyUs/> */}
         <div className="client-container">
         <span className="title-tech" >
              Clients
            </span>
            <Slider {...settingsClient}>
              {clients.map((image, i) => {
                return <img src={image} alt="" />
              })}
            </Slider>
          </div>
          {/* <OurCompany /> */}
          <Services />
          
          <div className="client-container" style={{borderTop:'none'}}>
          <span className="title-tech" >
              Technology
            </span>
            <Slider {...settingsTech}>
              {tech.map((image, i) => {
                return <img src={image} alt="" className="image-slide"/>
              })}
            </Slider>
          </div>

          <Solution />
          <div className="client-container">
          <span className="title-tech" >
              Partnerships
            </span>
            <Slider {...settingsClient}>
              {partner.map((image, i) => {
                return <img src={image} alt="" />
              })}
            </Slider>
          </div>
          <Insight />
          <Contact />
        </div>
        <Footer />
      </div>
    );
  }
}

export default withRouter(Home);
