import React, { Component } from 'react';
import { connect } from "react-redux";
import { func } from "prop-types";
import MUIDataTable from "mui-datatables";
import { getCvUser } from "../../actions/data";


class TableClass extends Component {
    constructor(props) {
        super(props);
        this.state = {
         file_name:""
        };
      };
     

      handleRowClick = (value, tableMeta) => {
        let query = {
            file_name: value,
          }
        this.props.downloadCV(query);
    };


    render() { 
        const columns = [
            {
                name: "first_name",
                label: "First Name",
                options: {
                    filter: true,
                    sort: true,
                }
            },
            {
                name: "last_name",
                label: "Last Name",
                options: {
                    filter: false,
                    sort: true,
                }
            },
            {
                name: "email",
                label: "Email",
                options: {
                    filter: false,
                    sort: true,
                    customBodyRender: (value) => {

                        var j = (value === null) ? 0 : value;
                        return j;
                    },
                }
            },
            {
                name: "phone",
                label: "Phone",
                options: {
                    filter: false,
                    sort: false,
                    customBodyRender: (value) => {
                        var j = (value === null) ? 0 : value;
                        return j;
                    },
                }

            },
            {
                name: "job_desc",
                label: "Job Desc",
                options: {
                    filter: false,
                    sort: false,
                    customBodyRender: (value) => {
                        var j = (value === null) ? 0 : value;
                        return j;
                    },
                }

            },
            {
                name: "message",
                label: "Message",
                options: {
                    filter: false,
                    sort: false,
                    customBodyRender: (value) => {

                        var j = (value === null) ? 0 : value;
                        return j;
                    },
                }

            },
            {
                name: "file_name",
                label: "Dowload CV",
                options: {
                    customBodyRender: (value, tableMeta, updateValue) => {
                        return (
                            <button onClick={() => this.handleRowClick(value, tableMeta) }>
                                Download CV
                            </button>
                        )
                    }
                }

            },
        ];
        const options = {
            filter: true,
            filterType: 'dropdown',
            responsive: 'stacked',
            selectableRows: false,
            rowsPerPage: 15,
            // serverSide: true,
        };
        const {data} = this.props;
        return (
            <div>
                 <MUIDataTable
                    data = {data}
                        columns={columns}
                        options={options}
                    />
            </div>
        );
    }
}

TableClass.propTypes = {
    downloadCV: func.isRequired
};

const mapStateToProps = ({ dataPGI: { cv, pending, error } }) => ({
    cv,
    pending,
    error
});

const mapDispatchToProps = dispatch => ({
    downloadCV: query => dispatch(getCvUser.request(query))
});

const Table = connect(mapStateToProps, mapDispatchToProps)(TableClass)

export default Table;