import React from 'react';
import { render } from "react-dom";
import './index.css';
import AOS from 'aos';
import { Routes, Route, BrowserRouter } from 'react-router-dom'
import "./index.css";
import "aos/dist/aos.css";
import "./style/index.scss"
import ScrollIntoView from './helpers/ScrollIntoView'

//component
import Career from './components/career';
import InsightPage from './components/insight/index';
import ContactPage from './components/contact-page';
import Home from "./components/Home";
import About from "./components/about"
import ServicePage from "./components/servicepage";
import DigitalPage from "./components/digital";
import SmartPage from "./components/smart";
import CloudPage from "./components/cloud";
import SecurityPage from "./components/security";
import Login from "./components/dashboard/login"
import Dashboard from "./components/dashboard/dashboard"


//redux
import { createStore, applyMiddleware, compose } from "redux";
import createSagaMiddleware from "redux-saga";
import rootReducer from "./reducers";
import rootSaga from "./sagas";
import { Provider } from "react-redux";
import SolutionService from './components/Solution-space-service';


AOS.init({
    disable: false,
    once: true,
    mirror: false
});
const sagaMiddleware = createSagaMiddleware();
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

let store = createStore(
  rootReducer,
  composeEnhancers(applyMiddleware(sagaMiddleware))
);

sagaMiddleware.run(rootSaga);

render(
  <Provider store={store}>
    <BrowserRouter>
      <ScrollIntoView>
        <Routes>
          <Route path='/' element={<Home />} />
          <Route path='/home' element={<Home />} />
          <Route path='/login' element={<Login />} />
          <Route path='/dashboard' element={<Dashboard />} />
          <Route path='/offerings' element={<ServicePage />} />
          <Route path='/about' element={<About />} />
          <Route path='/career' element={<Career />} />
          <Route path='/insights' element={<InsightPage />} />
          <Route path='/contact' element={<ContactPage />} />
          <Route path='/digitrans' element={<DigitalPage />} />
          <Route path='/smartanalytics' element={<SmartPage />} />
          <Route path='/cloud' element={<CloudPage />} />
          <Route path='/security' element={<SecurityPage />} />
          <Route path='/services' element={<SolutionService />} /> 
        </Routes>
      </ScrollIntoView>
    </BrowserRouter>
  </Provider>,
  document.getElementById("root")
);
