
import { put, takeEvery, call } from "redux-saga/effects";
import { getCvUser} from "../actions/data";
import axios from "axios";
var fileDownload = require('js-file-download');

export function getFileAPI(payload) {
  const apiUrl = process.env.REACT_APP_API_URL
  const url = apiUrl + "data/download?filename="+payload.file_name;
  const responseType = {
    responseType: 'blob',
  } 
  return axios.get(url,responseType);

}

export function* downloadRequest(action) {
  try {
    const response = yield call(getFileAPI, action.payload);

    if (response) {
      yield put(
        getCvUser.success({
          data: fileDownload(response.data, response.data.type === 'application/pdf'?'cv.pdf':response.data.type === 'image/jpeg'?'cv.jpg':'cv.png')
        })
      );
    }
  } catch (error) {
    yield put(getCvUser.failure(error.message));
  }
}

export default function* getFileSaga() {
  yield takeEvery(getCvUser.REQUEST, downloadRequest);
}
