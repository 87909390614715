import React, { Component } from 'react'
import { slide as Menu } from 'react-burger-menu'
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import './styles.scss';
import { withRouter } from '../helpers/withRouter';

class Header extends Component {
    state = {
        active: '00',
        menu: ['Home', 'About', 'Offerings', 'Insights', 'Career', 'Contact'],
        insight: 1
    }
    home = (menu,i) => {
        var str = "/";
        var str2 = menu.toLowerCase();
        var res = str.concat(str2);
        if (res === "/home") {
          this.props.navigate('/')
        } else {
          this.props.navigate(res)
        }
    };
    homePage = () => {
        this.props.navigate('/')
    };
    render() {
        return (
            <div>
                <header>
                    <img src="images/only-logo-clean.png" alt="" className="logo" onClick={() => this.homePage()} />
                    <div className="menu">
                        {this.state.menu.map((menu, i) => {
                            return <div 
                                onClick={() => this.home(menu,i)}
                                key={i}
                                to={menu.replace(' ', '')} spy={true}
                                offset={-70}
                                hashSpy={false}
                                smooth={true}
                                duration={500}
                                className="link"
                            > 
                                {menu}
                                
                            </div>
                            
                        })
                        }
                    </div>
                    <div className="menu-mobile">
                        <Menu right>
                            {this.state.menu.map((menu, i) => {
                                return <div key={i}
                                    to={menu.replace(' ', '')}
                                    onClick={() => this.home(menu,i)}
                                    spy={true}
                                    offset={-70}
                                    hashSpy={false}
                                    smooth={true}
                                    duration={500}
                                    className="link"
                                    style={{fontWeight: 'bold', color: '#002370'}}
                                >
                                    
                                    {menu}
                                </div>
                            })
                            }
                        </Menu>
                    </div>
                </header>
            </div>
        )
    }
}

export default withRouter(Header)
