import React, { Component } from 'react';
import { postJob, getUserJob, getInsight, postInsight, updateInsight, deleteInsight } from "../../actions/data";
import { connect } from "react-redux";
import { func } from "prop-types";
import Table from "./table"
import Table2 from "./table2"
import { withRouter } from '../../helpers/withRouter';

import { TextField, Select, MenuItem } from '@mui/material';

class DashboardClass extends Component {
    constructor(props) {
        super(props);

        if (localStorage.getItem("page") === null) {
            localStorage.setItem("page", "1");
        }
 
        this.state = {
            location: "",
            experience: "",
            title: "",
            description: "",
            passerr: false,
            success: false,
            showinput: localStorage.getItem("page"),
            user: "",
            id:"",
            titleIns: "",
            subtitleIns: "",
            shortdescIns: "",
            urlIns: "",
            fileInst: "",
            imgInst: "",
            categoryInst: "",
            opacity: "1",
            pageTitle: "Input"
        };
    }

    handleOpenInput() {
        localStorage.setItem("page", "1");
        this.forceUpdate()
    };

    handleOpenInsight() {
        localStorage.setItem("page", "2");
        this.forceUpdate();
        this.setState({ pageTitle:"Input"});
        this.setState({ titleIns:""});
        this.setState({ subtitleIns: ""});
        this.setState({ shortdescIns: "" });
        this.setState({ urlIns: "" });
        this.setState({ fileInst: "" });
        this.setState({ imgInst: "" });
        this.setState({ categoryInst: "" });
    };

    handleOpenData() {
        localStorage.setItem("page", "3");
        this.forceUpdate()
    };

    handleDataInsight() {
        localStorage.setItem("page", "4");
        this.props.fetchInsight();
        this.forceUpdate()
    };

    handleOpenLogout() {
        this.props.navigate("/login")
        localStorage.removeItem("pgiusername");
        // this.props.clear()
    };

    handleChangeExperience = event => {
        this.setState({ experience: event.target.value });
    };

    handleChangeLocation = event => {
        this.setState({ location: event.target.value });
    };

    handleChangeTitle = event => {
        this.setState({ title: event.target.value });
    };

    handleChangedescription = event => {
        this.setState({ description: event.target.value });
    };

    handleChangeTitleIns = event => {
        this.setState({ titleIns: event.target.value });
    };

    handleChangeSubtitleIns = event => {
        this.setState({ subtitleIns: event.target.value });
    };

    handleChangeShortdescIns = event => {
        this.setState({ shortdescIns: event.target.value });
    };

    handleChangeUrlIns = event => {
        this.setState({ urlIns: event.target.value });
        // this.setState({ urlIns: event.target.files[0] });
    };

    handleChangeCategoryIns = event => {
        this.setState({ categoryInst: event.target.value });
    };

    handleChangeFileIns = event => {
        var FileSize = event.target.files[0].size / 1024 / 1024; // in MiB
        if (FileSize > 10) {
            alert('File size exceeds 10 MiB. Please choose another file!');
        } else {
            this.setState({ fileInst: event.target.files[0] });
        }
    };

    handleChangeImgIns = event => {
        var FileSize = event.target.files[0].size / 1024 / 1024; // in MiB
        if (FileSize > 2) {
            alert('Image size exceeds 2 MiB. Please choose another image!');
        } else {
            this.setState({ imgInst: event.target.files[0] });
        }
    };

    handleSubmit = () => {
        const { insertjob } = this.props;
        let post_job_data = {
            location: this.state.location,
            experience: this.state.experience,
            jobdesc: this.state.title,
            desc: this.state.description
        };
        insertjob(post_job_data);
    };

    handleSubmitInst = () => {
        const { insertinsight } = this.props;
 
        if(this.state.pageTitle === "Input") {
            let post_insight_data = {
                title: this.state.titleIns,
                subtitle: this.state.subtitleIns,
                shortdesc: this.state.shortdescIns,
                url: this.state.urlIns,
                file: this.state.fileInst,
                image: this.state.imgInst,
                category: this.state.categoryInst
            };

            insertinsight(post_insight_data);

            this.setState({ opacity: 1 });
            this.setState({ titleIns:""});
            this.setState({ subtitleIns: ""});
            this.setState({ shortdescIns: "" });
            this.setState({ urlIns: "" });
            this.setState({ fileInst: "" });
            this.setState({ imgInst: "" });
            this.setState({ categoryInst: "" });
        } else {
            
            let update_insight_data = {
                id: this.state.id,
                title: this.state.titleIns,
                subtitle: this.state.subtitleIns,
                shortdesc: this.state.shortdescIns,
                url: this.state.urlIns,
                file: this.state.fileInst,
                image: this.state.imgInst,
                category: this.state.categoryInst
            };
            
            this.props.editinsight(update_insight_data); 
        }

     
        
    }
    
    componentDidMount = () => {
        const user = localStorage.getItem('pgiusername');
        const { fetchUserJob, fetchInsight } = this.props;
        if (user === null) {
            this.props.navigate("/login")
        } else {
            fetchUserJob();
            fetchInsight();
            this.setState({ user: this.props.get_user_job });
            let data =  JSON.parse(localStorage.getItem('insight'));
            
            if(localStorage.getItem('edit') === "true") {
                this.setState({ id: data[7] });
                this.setState({ titleIns: data[1] });
                this.setState({ subtitleIns: data[2] });
                this.setState({ shortdescIns: data[3] });
                this.setState({ urlIns: data[4] });
                this.setState({ fileInst: data[6] });
                this.setState({ imgInst: data[5] });
                this.setState({ categoryInst: data[0] });
                this.setState({ pageTitle: "Update" });
                localStorage.setItem("edit", "false");
            } 
        }
    };

    componentDidUpdate(prevProps){
        if(prevProps.delete_insight !== this.props.delete_insight){
          window.location.reload();
        }
    }

    render() {
        const {
            post_insight,
            update_insight,
            get_insight,
            deleteinsight,
            post_job,
            get_user_job,
            pending,
            error } = this.props;
            let name = localStorage.getItem("pgiusername");
        const getuserjob = [...get_user_job]

        setTimeout(() =>
          this.setState({ opacity: 0 }), 5000
        ) 

        return (
            <div>
                <div className="dashboard-head">
                    <div className="content-dash">
                        <div className="menu-dash">
                            <h4 onClick={() => this.handleOpenLogout()} className="menu-dash2"> Logout</h4>
                            <h4 onClick={() => this.handleOpenInput()} className="menu-dash2"> Input Job</h4>
                            <h4 onClick={() => this.handleOpenInsight()} className="menu-dash2"> Input Insight</h4>
                            <h4 onClick={() => this.handleOpenData()} className="menu-dash2"> Job Data</h4>
                            <h4 onClick={() => this.handleDataInsight()} className="menu-dash2"> Insight Data</h4>
                        </div>
                        <h3>{name}</h3>
                    </div>
                </div>
                <div className="dashboard" >
                    {localStorage.getItem("page") === "1" &&
                        <div>
                            <h1>Input Job</h1>
                            <form className="form">
                                <TextField
                                    value={this.state.name}
                                    onChange={event => this.handleChangeTitle(event)}
                                    className="forms"
                                    type="text"
                                    id="Title"
                                    name="Job Title"
                                    placeholder="Job Title"
                                    variant="outlined" />
                                <TextField
                                    value={this.state.name}
                                    onChange={event => this.handleChangeLocation(event)}
                                    className="forms"
                                    type="text"
                                    id="location"
                                    name="location"
                                    placeholder="Location"
                                    variant="outlined" />
                                <TextField
                                    value={this.state.name}
                                    onChange={event => this.handleChangeExperience(event)}
                                    className="forms"
                                    type="number" id="experience"
                                    name="experience"
                                    placeholder="Min Experience *years"
                                    variant="outlined" />
                                <TextField
                                    value={this.state.name}
                                    onChange={event => this.handleChangedescription(event)}
                                    multiline={true}
                                    rows={10}
                                    className="forms"
                                    type="text" id="description"
                                    name="description"
                                    placeholder="Description"
                                    variant="outlined" />
                                <button onClick={() => this.handleSubmit()} type="button" value="Check" className="login">Input</button>
                            </form>
                            { post_job.status === "OK" ?
                            <div className="alertD">
                                 <h4 className="text-alerts">Success Input the data</h4>
                            </div>
                            : error ?
                            <div className="alertDD">
                                <h4 className="text-alerts">Failed Input the data</h4>
                            </div>
                            : null}
                        </div> }
                        {localStorage.getItem("page") === "2" &&
                        <div>
                            <h1>{this.state.pageTitle} Insight</h1>
                            <form className="form">
                            
                               <Select
                                    id="categoryInst"
                                    value={this.state.categoryInst}
                                    onChange={this.handleChangeCategoryIns}
                                    displayEmpty
                                    variant="outlined"
                                    inputProps={{ 'aria-label': 'Without label' }}
                                    style={{opacity: this.state.categoryInst === '' ? '0.5' : '1'}}
                                    >
                                    <MenuItem value="" disabled>
                                       Category
                                    </MenuItem>
                                    <MenuItem value={'1'}>Blog</MenuItem>
                                    <MenuItem value={'2'}>Event</MenuItem>
                                    <MenuItem value={'3'}>Case Study</MenuItem>
                                </Select>
                                {this.state.categoryInst !== '2' && 
                                <>
                                <TextField
                                    value={this.state.titleIns}
                                    onChange={event => this.handleChangeTitleIns(event)}
                                    className="forms"
                                    type="text"
                                    id="titleIns"
                                    name="titleIns"
                                    placeholder="Title"
                                    variant="outlined" />
                                <TextField
                                    value={this.state.subtitleIns}
                                    onChange={event => this.handleChangeSubtitleIns(event)}
                                    className="forms"
                                    type="text"
                                    id="subtitleIns"
                                    name="subtitleIns"
                                    placeholder="Subtitle"
                                    variant="outlined" />
                                <TextField
                                    value={this.state.shortdescIns}
                                    onChange={event => this.handleChangeShortdescIns(event)}
                                    multiline={true}
                                    rows={10}
                                    className="forms"
                                    type="text"
                                    id="shortdescIns"
                                    name="shortdescIns"
                                    placeholder="Short Description"
                                    variant="outlined" />
                                <TextField
                                    value={this.state.urlIns}
                                    onChange={event => this.handleChangeUrlIns(event)}
                                    className="forms"
                                    type="text" 
                                    id="urlIns"
                                    name="urlIns"
                                    placeholder="Url"
                                    variant="outlined" />
                                    <h4 style={{margin: '15px 0'}}> File PDF</h4>
                                <input
                                    onChange={event => this.handleChangeFileIns(event)}
                                    accept="image/jpeg,image/gif,image/png,application/pdf,image/x-eps"
                                    id="file-button"
                                    multiple
                                    type="file"
                                />
                                </>
                                }
                                <h4 style={{ margin: '15px 0' }}> Image </h4>
                                <input
                                    onChange={event => this.handleChangeImgIns(event)}
                                    accept="image/jpeg,image/gif,image/png,application/pdf,image/x-eps"
                                    id="image-button"
                                    multiple
                                    type="file"
                                />
                                <button onClick={() => this.handleSubmitInst()} type="button" value="Check" className="login">{this.state.pageTitle}</button>
                            </form>
                            { post_insight.status === "OK" || update_insight.status === "OK" ?
                            <div className="alertD"
                            style={{ 
                                transition: 'opacity 0.3s ease',
                                opacity: this.state.opacity
                              }} 
                            >
                                 <h4 className="text-alerts">Success {this.state.pageTitle} the data</h4>
                            </div>
                            : error && this.state.imgInst === '' ?
                            <div className="alertDD"
                            style={{ 
                                transition: 'opacity 0.3s ease',
                                opacity: this.state.opacity
                              }} 
                            >
                                <h4 className="text-alerts">Failed {this.state.pageTitle} the data. Image can not be empty.</h4>
                            </div>
                            : error ?
                            <div className="alertDD"
                            style={{ 
                                transition: 'opacity 0.3s ease',
                                opacity: this.state.opacity
                              }} 
                            >
                                <h4 className="text-alerts">Failed {this.state.pageTitle} the data</h4>
                            </div>
                            : null}
                        </div> }
                        {localStorage.getItem("page") === "3" &&
                        <>
                         <h1>Job List</h1>
                            <Table
                                data={getuserjob}
                                pending={pending}
                                error={error} />
                        </>
                        }
                        {localStorage.getItem("page") === "4" &&
                        <>
                         <h1>Insight List</h1>
                            <Table2
                                fetchInsight={this.props.fetchInsight}
                                deleteInsight={deleteinsight}
                                data={get_insight.sort((a, b) => b.id - a.id)}
                                pending={pending}
                                error={error} />
                        </>
                        }
                </div>
            </div>

        );
    }
}

DashboardClass.propTypes = {
    fetchInsight: func.isRequired,
    insertinsight: func.isRequired,
    editinsight: func.isRequired,
    fetchUserJob: func.isRequired,
    insertjob: func.isRequired,
    deleteinsight: func.isRequired
};

const mapStateToProps = ({ dataPGI: { post_insight, update_insight, get_insight, delete_insight, post_job, get_user_job, pending, error } }) => ({
    post_insight,
    update_insight,
    get_insight,
    delete_insight,
    post_job,
    get_user_job,
    pending,
    error
});

const mapDispatchToProps = dispatch => ({
    fetchUserJob: () => dispatch(getUserJob.request()),
    insertjob: post_job_data => dispatch(postJob.request(post_job_data)),
    fetchInsight: () => dispatch(getInsight.request()),
    insertinsight: post_insight_data => dispatch(postInsight.request(post_insight_data)),
    editinsight: update_insight_data => dispatch(updateInsight.request(update_insight_data)),
    deleteinsight: id => dispatch(deleteInsight.request(id))
});

const Dashboard = connect(mapStateToProps, mapDispatchToProps)(DashboardClass)

export default withRouter(Dashboard);