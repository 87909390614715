import { createRoutine } from "redux-saga-routines";

export const GET_JOB = "GET_LOGIN";
export const getJob = createRoutine(GET_JOB);

export const POST_JOB = "POST_JOB";
export const postJob = createRoutine(POST_JOB);

export const POST_USER_JOB = "POST_USER_JOB";
export const postUserJob = createRoutine(POST_USER_JOB);

export const GET_USER_JOB = "GET_USER_JOB";
export const getUserJob = createRoutine(GET_USER_JOB);

export const GET_CV_USER = "GET_CV_USER";
export const getCvUser = createRoutine(GET_CV_USER);

export const GET_INSIGHT = "GET_INSIGHT";
export const getInsight = createRoutine(GET_INSIGHT);

export const POST_INSIGHT = "POST_INSIGHT";
export const postInsight = createRoutine(POST_INSIGHT);

export const UPDATE_INSIGHT = "UPDATE_INSIGHT";
export const updateInsight = createRoutine(UPDATE_INSIGHT);

export const DELETE_INSIGHT = "DELETE_INSIGHT";
export const deleteInsight = createRoutine(DELETE_INSIGHT);