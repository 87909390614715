import { put, takeEvery, call } from "redux-saga/effects";
import { updateInsight } from "../actions/data";
import axios from "axios";

export function editInsightAPI(payload) {
  const apiUrl = process.env.REACT_APP_API_URL
  const url = apiUrl + "data/insight";
  const data = new FormData()
  data.append('id', payload.id);
  data.append('title', payload.title);
  data.append('subtitle', payload.subtitle);
  data.append('shortdesc', payload.shortdesc);
  data.append('url', payload.url);
  //data.append('desc', payload.desc);
  data.append('category', payload.category);
 
  if (typeof payload.file !== 'string') {
    data.append('file', payload.file,payload.file.name);
   }else {
    data.append('file', payload.file);
   }

   if (typeof payload.image !== 'string') {
    data.append('image', payload.image,payload.image.name);
   }else {
    data.append('image', payload.image);
   }

  
  const headers = { 'accept': "*/*",
    "Accept-Language": "en-US,en;q=0.8",
    "Content-Type": `multipart/form-data;`
  }

  return axios.put(url, data, headers);
}

export function* updateInsightRequest(action) {
  try {
    const response = yield call(editInsightAPI, action.payload);

    if (response) {
      yield put(
        updateInsight.success({
          data: response.data,
          meta: response.data.meta,
        })
      );
    }
  } catch (error) {
    yield put(updateInsight.failure(error.message));
  }
}

export default function* updateInsightSaga() {
  yield takeEvery(updateInsight.REQUEST, updateInsightRequest);
}