import React, { Component } from 'react';
import { connect } from "react-redux";
import { func } from "prop-types";
import MUIDataTable from "mui-datatables";
import { getCvUser } from "../../actions/data";
import { Edit, Delete } from '@mui/icons-material';

class TableClass extends Component {
    constructor(props) {
        super(props);
        this.state = {
         file_name:""
        };
      };
    
    handleDeleteInst = (value, tableMeta) => {
        const { deleteInsight } = this.props;
        let id = {
            id : value
        };
        deleteInsight(id);
        this.props.fetchInsight();
        this.forceUpdate()
    }

    handleEditInst = (value, tableMeta) => {
        localStorage.setItem("page", "2");
        localStorage.setItem("edit", "true");
        localStorage.setItem("insight", JSON.stringify(tableMeta.rowData));
        window.location.reload();
    }

    render() { 
        const columns = [
            {
                name: "category",
                label: "Category",
                options: {
                    filter: false,
                    sort: false,
                    customBodyRender: (value) => {
                        var j = (value === null) ? 0 : value;
                        if (j === '1') {
                            return 'Blog'
                        } else if (j === '2') {
                            return 'Event'
                        } else if (j === '3') {
                            return 'Case Study'
                        } else {
                            return j;
                        }
                    },
                }

            },
            {
                name: "title",
                label: "Title",
                options: {
                    filter: true,
                    sort: true,
                }
            },
            {
                name: "subtitle",
                label: "Sub Title",
                options: {
                    filter: false,
                    sort: true,
                }
            },
            {
                name: "shortdesc",
                label: "Short Description",
                options: {
                    filter: false,
                    sort: true,
                }
            },
            {
                name: "url",
                label: "Url",
                options: {
                    filter: false,
                    sort: true,
                }
            },
            {
                name: "image",
                label: "Image",
                options: {
                    customBodyRender: (value, tableMeta, updateValue) => {
                        return (
                            <img src={"http://pgi-data.com/data/images/"+value} width="150" height="150" style={{objectFit:"contain"}} alt="" />
                        )
                    }
                }

            },
            {
                name: "file",
                label: "File",
                options: {
                    filter: false,
                    sort: true,
                }
            },
            {
                name: "id",
                label: "Action",
                options: {
                    customBodyRender: (value, tableMeta, updateValue) => {
                        return (
                            <div style={{display:'flex'}}>
                                <div style={{cursor:"pointer"}} onClick={() => this.handleEditInst(value, tableMeta) }>
                                  <Edit style={{color:'#FFCC00'}}/>
                                </div>
                                <div style={{cursor:"pointer"}} onClick={() => this.handleDeleteInst(value, tableMeta) }>
                                  <Delete style={{color:'red'}}/>
                                </div>
                            </div>
                        )
                    }
                }

            },
        ];
        const options = {
            filter: true,
            filterType: 'dropdown',
            responsive: 'stacked',
            selectableRows: false,
            rowsPerPage: 15,
            // serverSide: true,
        };
        const {data} = this.props;
        return (
            <div>
                 <MUIDataTable
                    data = {data}
                        columns={columns}
                        options={options}
                    />
            </div>
        );
    }
}

TableClass.propTypes = {
    downloadCV: func.isRequired
};

const mapStateToProps = ({ dataPGI: { cv, pending, error } }) => ({
    cv,
    pending,
    error
});

const mapDispatchToProps = dispatch => ({
    downloadCV: query => dispatch(getCvUser.request(query))
});

const Table = connect(mapStateToProps, mapDispatchToProps)(TableClass)

export default Table;