import React, { Component } from 'react'
import '../styles.scss';
import Event from "./event"
import CaseStudy from "./case-study"
import Blog from "./blog"
import Header from '../header';
import { connect } from "react-redux";
import { func } from "prop-types";
import { getInsight } from '../../actions/data';

class InsightClass extends Component {
   
    state = {
            active: '00',
            insight: 2
        }
   
    componentDidMount = () => {
        const { fetchInsight } = this.props;
        fetchInsight();
    };
    
    home = (menu, i) => {
        var str = "/";
        var str2 = menu.toLowerCase();
        var res = str.concat(str2);

        this.props.history.push(res)
    };

    case() {
        this.setState({ insight: 1 });
    };
    blog() {
        this.setState({ insight: 2 });
    };
    event() {
        this.setState({ insight: 3 });
    };
    render() {

        return (
            <div id="Insights">
                <Header />
                <div id="Insight" className="insight-component">
                    <div className="insight-content">
                        <img src="images/insight/insight-left.png" alt="" className="logo"
                            data-aos="fade-right"
                            data-aos-delay="500"
                            data-aos-duration="1000" />
                                <img src="images/insight/blogs.png" alt="" onClick={() => this.blog()} className="blog" />
                                <img src="images/insight/event.png" alt="" onClick={() => this.event()} className="event" />
                                <img src="images/insight/case-studies.png" onClick={() => this.case()} alt="" className="cs" />
                                <img src="images/insight/insight-right.png" alt="" className="logo"
                            data-aos="fade-right"
                            data-aos-delay="500"
                            data-aos-duration="1000" />
                    </div>
                    <div>
                        <h1 className="insight" >insights</h1>
                    </div>
                </div>
                {/* <div className="insightpage" >
                    <div className="insight-banner">
                        <h1 className="text">INSIGHTS</h1>
                        <div className="button">
                            <button className={this.state.insight === 1 ? "btn" : 'btnActive'} onClick={() => this.case()}>Case studies</button>
                            <button className={this.state.insight === 2 ? "btn" : 'btnActive'} onClick={() => this.blog()}>Blogs</button>
                            <button className={this.state.insight === 3 ? "btn" : 'btnActive'} onClick={() => this.event()}>Events</button>
                        </div>

                    </div>
                </div> */}
                {this.state.insight === 1 ?
                    <CaseStudy cases={this.props.get_insight.filter(i => (i.category ==='3')).sort((a, b) => b.id - a.id)} /> :
                    this.state.insight === 2 ? <Blog blog={this.props.get_insight.filter(i => (i.category === '1')).sort((a, b) => b.id - a.id)} /> :
                       <Event event={this.props.get_insight.filter(i => (i.category === '2')).sort((a, b) => b.id - a.id)} />}
            </div>
        )
    }
}

InsightClass.propTypes = {
    fetchInsight: func.isRequired
};

const mapStateToProps = ({ dataPGI: { get_insight, pending, error } }) => ({
    get_insight,
    pending,
    error
});

const mapDispatchToProps = dispatch => ({
    fetchInsight: () => dispatch(getInsight.request())
});

const InsightPage = connect(mapStateToProps, mapDispatchToProps)(InsightClass)

export default InsightPage;
