
import { put, takeEvery, call } from "redux-saga/effects";
import { getInsight} from "../actions/data";
import axios from "axios";

export function getInsightAPI(payload) {
  const apiUrl = process.env.REACT_APP_API_URL
  const url = apiUrl + "data/insight";
  return axios.get(url);
}

export function* insightRequest(action) {
  try {
    const response = yield call(getInsightAPI, action.payload);

    if (response) {
      yield put(
        getInsight.success({
          data: response.data.data
        })
      );
    }
  } catch (error) {
    yield put(getInsight.failure(error.message));
  }
}

export default function* getInsightSaga() {
  yield takeEvery(getInsight.REQUEST, insightRequest);
}
