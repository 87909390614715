import React, { Component } from "react";

class OurCompany extends Component {

  kaustabh() {
    window.open("https://www.linkedin.com/in/kaustabhroy/");
  };
  umesh() {
    window.open("https://www.linkedin.com/in/umeshgupta/");
  };
  christina() {
    window.open("https://www.linkedin.com/in/christina-widjaja-96697b40/");
  };
  soumita() {
    window.open("https://www.linkedin.com/in/soumitade");
  };
  andrew() {
    window.open("https://www.linkedin.com/in/andrew-wibowo-00b158132/");
  };
  jonas() {
    window.open("https://www.linkedin.com/in/jonaslander/");
  };

  render() {
    return (
      <div id="OURCOMPANY" className="our-company-component">
        <div className="leadership"
          data-aos="fade-up"
          data-aos-delay="50"
          data-aos-duration="1000"
        >
          <div className="box">
            <div className="detail">
              <div className="base" />
              <img src="images/leadership/kaustabh.png" alt="" style={{ width: '130px', objectFit: 'contain' }} />
              <div className='boxblue'>
              <div style={{height:'106px'}}>
                <h3 style={{ marginBlockEnd: '6px', color: "white", fontSize: "17pt" }}>Kaustabh Roy</h3>
                <h4 style={{ marginBlockStart: 0, color: "#FFCC00", fontSize: "11pt" }}> 
                  Founder & Chief Executive Officer​​<br/>Jakarta</h4>
                  </div>
                <div className="text">
                10+ years in Strategic Consulting, sales & operations, Preparing and implementing comprehensive business plans to facilitate achievement.​
              </div>
              </div>
              <img src="images/li.png" alt="" className="linkedin" onClick={() => this.kaustabh()} />
            </div>
            {/* <div className="detail">
              <div className="base" />
              <img src="images/leadership/umesh.png" alt="" style={{ width: '130px', objectFit: 'contain' }} />
              <div className='boxblue'>
                <h3 style={{ marginBlockEnd: '6px', color: "white", fontSize: "17pt" }}>Umesh Gupta</h3>
                <h4 style={{ marginBlockStart: 0, color: "#FFCC00", fontSize: "11pt", marginBottom:'55px' }}>CEO APAC Region​​<br/></h4>
                <div className="text">
                  25+ years working on Telco digital transformation, eMoney platforms, security services
              </div>
              </div>
              <img src="images/li.png" alt="" className="linkedin" onClick={() => this.umesh()} />
            </div> */}
             <div className="detail">
              <div className="base" />
              <img src="images/leadership/bistha.png" alt="" style={{ width: '130px', objectFit: 'contain' }} />
              <div className='boxblue'>
                <div style={{height:'106px'}}>
                <h3 style={{ marginBlockEnd: '6px', color: "white", fontSize: "17pt" }}>Bistha Annissya</h3>
                <h4 style={{ marginBlockStart: 0, color: "#FFCC00", fontSize: "11pt" }}>Head of Business​<br/>Jakarta</h4>
                </div>
                <div className="text">
                10+ years in developing
business potentials; Preparing and delivering pitches and presentations to potential
new clients; maintain positive business and customer relationships.
              </div>
              </div>
              <img src="images/li.png" alt="" className="linkedin" onClick={() => this.soumita()} />
            </div>    
            <div className="detail">
              <div className="base" />
              <img src="images/leadership/andrew.png" alt="" style={{ width: '130px', objectFit: 'contain' }} />
              <div className='boxblue'>
              <div style={{height:'106px'}}>
                <h3 style={{ marginBlockEnd: '6px', color: "white", fontSize: "17pt" }}>Andrew Wibowo</h3>
                <h4 style={{ marginBlockStart: 0, color: "#FFCC00", fontSize: "11pt" }}>Advisor<br/>Jakarta</h4>
                </div>
                <div className="text">
                15+ years of designing policies, overseeing customer service and implementing technology solutions​
              </div>
              </div>
              <img src="images/li.png" alt="" className="linkedin" onClick={() => this.andrew()} />
            </div>     
            <div className="detail">
              <div className="base" />
              <img src="images/leadership/jonas.png" alt="" style={{ width: '130px', objectFit: 'contain' }} />
              <div className='boxblue'>
              <div style={{height:'106px'}}>
                <h3 style={{ marginBlockEnd: '6px', color: "white", fontSize: "17pt" }}>Jonas Lander</h3>
                <h4 style={{ marginBlockStart: 0, color: "#FFCC00", fontSize: "11pt" }}>Senior Consultant​​<br/>Jakarta</h4>
                </div>
                <div className="text">
                15+ years as Principal Consultant and highly experienced in Telecoms & Technology program, carrier networks, sales​
              </div>
              </div>
              <img src="images/li.png" alt="" className="linkedin" onClick={() => this.jonas()} />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default OurCompany;
