
import { put, takeEvery, call } from "redux-saga/effects";
import { getJob} from "../actions/data";
import axios from "axios";

export function getJobAPI(payload) {
  const apiUrl = process.env.REACT_APP_API_URL
  const url = apiUrl + "data/job";
  return axios.get(url);
}

export function* jobRequest(action) {
  try {
    const response = yield call(getJobAPI, action.payload);

    if (response) {
      yield put(
        getJob.success({
          data: response.data.data
        })
      );
    }
  } catch (error) {
    yield put(getJob.failure(error.message));
  }
}

export default function* getJobSaga() {
  yield takeEvery(getJob.REQUEST, jobRequest);
}
