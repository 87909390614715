import React, { Component } from 'react'

export class OurValues extends Component {
    render() {
        return (
            <>
            <div className="our-values-component">
            <div>
                <h3>PGI DATA AT A GLANCE</h3>
                <div className="our-values">
                    <div className="box">
                        <div>
                            <img src="images/ours/1.png" width="50px" alt=""/>
                        </div>
                        <div className="text">
                            2018: Founded in Jakarta, Indonesia​
                        </div>
                    </div>
                    <div className="box">
                        <div>
                            <img src="images/ours/2.jpg" width="50px" alt=""/>
                        </div>
                        <div className="text">
                            80+ Strong management and technical team ​
                        </div>
                    </div>
                    <div className="box">
                        <div>
                            <img src="images/ours/3.png" width="120px" alt=""/>
                        </div>
                        <div className="text">
                            Offshore delivery partner center (700+ professionals)​
                        </div>
                    </div>
                    <div className="box">
                        <div>
                            <img src="images/ours/4.png" width="50px" alt=""/>
                        </div>
                        <div className="text">
                            Serving 20+ medium & large enterprises​
                        </div>
                    </div>
                    <div className="box">
                        <div>
                            <img src="images/ours/5.png" width="50px" alt=""/>
                        </div>
                        <div className="text">
                            Focused on BFSI, Telecom. and Retail​
                        </div>
                    </div>
                </div>
            </div>
            <div className="our-values2">
                <h3 style={{paddingBottom: '5px'}}>OUR VALUES</h3>
                <div className="img-cont">
                   <img src="images/ours/ourvalues.jpg" width="692px" className="wallet" alt=""/>   
                   <div className="text-cont">
                    <div className="text">
                        <span className="title">Innovation : </span>
                        Continuously innovate to deliver AI led intelligent automated solutions​
                    </div>
                    <div className="text">
                        <span className="title">Productivity : </span>
                        Focused on time delivery with Automated Data processes​
                    </div>
                    <div className="text">
                        <span className="title">Efficiency : </span>
                        Improving product quality and consistency in rapidly growing environments​
                    </div>
                    <div className="text">
                        <span className="title">Inclusive : </span>
                        Embrace diversity to promote innovation and creativity
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="our-vision">
                <h3>OUR VISION & MISSION</h3>
                <div className="title">
                We <span className="bold">aim</span> to create experiences with smart​ data - driven digital solutions
                </div>
                <div className="box-container">
                  <div className="box">
                      <div>
                          <img src="images/ours/eye.svg" width="100px" alt=""/>
                      </div>
                      <div className="text2">
                          We <span className="bold">Envision</span> to channelise organization in the right direction.
                      </div>
                  </div>
                  <div className="box">
                      <div>
                          <img src="images/ours/code.svg" width="100px" alt=""/>
                      </div>
                      <div className="text2">
                         We <span className="bold">Explore</span> the business opportunities in depth 
                      </div>
                  </div>
                  <div className="box">
                      <div>
                          <img src="images/ours/processing.svg" width="100px" alt=""/>
                      </div>
                      <div className="text2">
                      We <span className="bold">Execute</span> digital transformation journey with best in class technology
                      </div>
                  </div>
                  </div>
              </div>
              </>
        )
    }
}

export default OurValues
