
import { put, takeEvery, call } from "redux-saga/effects";
import { postLogin } from "../actions/login";
import axios from "axios";

export function loginAPI(payload) {
  const apiUrl = process.env.REACT_APP_API_URL
  const url = apiUrl + "auth/login";
  const data = {
    username: payload.username,
    password: payload.password
  };

  return axios.post(url, data);
}

export  function* loginRequest(action) {
  try {
    const response = yield call(loginAPI, action.payload);
  
    if (response) {
      yield put(
        postLogin.success({
          data: response.data,
        })  
      );
    }
    if (response.data.status !== "Error") {
      localStorage.setItem("pgiusername", response.data.data.username);
    }
  } catch (error) {
    yield put(postLogin.failure(error.message));
  }
}


export default function* loginSaga() {
  yield takeEvery(postLogin.REQUEST, loginRequest);
}
