import React,  { Component } from "react";
import { withRouter } from "../helpers/withRouter";

class Insight extends Component {
  constructor(props){
    super(props);
    this.state = {
         test: 3
    }
}

  event  = () =>{
 
    this.props.navigate('/insights')
  };
  blog  = () =>{
    this.props.navigate("/insights")
  };
  cs  = () =>{
    this.props.navigate("/insights")
  };
  Insight = () =>{
    this.props.navigate("/insights")
  };

 render(){
  return (
    <div id="INSIGHT" className="insight-component">
        <div className="insight-content">
          <img src="images/insight/insight-left.png" alt="" className="logo"/>
            <img src="images/insight/blogs.png" alt="" onClick={() => this.blog()} className="blog"/>
            <img src="images/insight/event.png" alt=""onClick={() => this.event()} className="event"/>
            <img src="images/insight/case-studies.png" onClick={() => this.cs()} alt="" className="cs"/>
            <img src="images/insight/insight-right.png" onClick={() => this.Insight()} alt="" className="logo"/>
      </div>
      <div>
        <h1 className="insight" >insights</h1>
      </div>
    </div>
  );
  }
}

export default withRouter(Insight) ;
