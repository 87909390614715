import React, { Component } from 'react'


class CaseStudy extends Component {


    render() {
        return (
         <>
        { this.props.cases.length !== 0 && this.props.cases.map(c=> {
            return (
            <div key={c.id} className="casestudy">
                <div className="content">
                    <div className="box">
                    <img src={"http://pgi-data.com/data/images/"+c.image} className="image-cs" alt="" />
                    <div className="boxyellow">
                        <span className="heading" style={{marginTop:'20px'}}>{c.title}</span>
                        <span className="subheading" ></span>
                        <span className="content">
                        {c.shortdesc}
                        <br></br><strong style={{marginTop:'20px'}}>
                        <a href={c.file !== '' ? "https://pgi-data.com/data/download?filename="+c.file : c.url} download style={{cursor:'pointer', color: '#002370'}}>Read On.</a></strong>
                        </span>
                        <a href={c.file !== '' ? "https://pgi-data.com/data/download?filename="+c.file : c.url}  download className="download" > 
                        <i className= "fa fa-download"></i>&nbsp;
                        Download</a>
                    </div>
                    </div>
                </div>
            </div>
             )
          })}
         </>
        )
    }
}

export default CaseStudy
