import React, { Component } from 'react'
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import './styles.scss';
import WhyUs from './why-us';
import OurCompany from "./Our-company";
import Header from './header';
import OurValues from './our-values';


function importAll(r) {
    let images = [];
    r.keys().map((item, index) => {
        return images.push(item.replace('./', 'images/about/'))
    });
    return images;
}
// function importAll2(r) {
//     let images = [];
//     r.keys().map((item, index) => {
//       return images.push(item.replace('./', 'images/clients/blackwhite3/'))
//     });
//     return images;
//   }


// const clients = importAll2(require.context('../../public/images/clients/blackwhite3/', false, /\.(png|jpe?g|svg)$/));
const slide = importAll(require.context('../../public/images/about/', false, /\.(png|jpe?g|svg)$/));
class About extends Component {
    state = {
        active: '00',
        menu: ['HOME', 'ABOUT', 'SERVICES', 'INSIGHT', 'CAREER', 'CONTACT'],
    }
    render() {
        const settingsBanner = {
            dots: true,
            infinite: true,
            autoplay: true,
            speed: 500,
            autoplaySpeed: 5000,
            slidesToShow: 1,
            slidesToScroll: 1,
            arrows: false
        };
        // const settingsClient = {
        //     dots: false,
        //     infinite: true,
        //     autoplay: true,
        //     speed: 2000,
        //     autoplaySpeed: 3000,
        //     slidesToShow: 4,
        //     slidesToScroll: 1,
        //     arrows: false,
        //   };
        return (
            <div>
                <Header/>
                <div className="aboutt" id="About" >
                    <div className="slider"
                        data-aos="fade-up"
                        data-aos-delay="500"
                        data-aos-duration="1000"
                        data-aos-mirror="true">
                        <Slider {...settingsBanner}>
                            {slide.map((image, i) => {
                                return <img src={image} alt="" className="about-slide"/>
                            })}
                        </Slider> 
                    </div>
                    <div className="aboutP">
                        <img src="images/logo-pgi.png" className="logo-big" alt="" />
                        <div className="about-title">Delivering Data Driven Digital Solutions</div>
                        <div className="text-about">
                
                            PGI Data is a boutique house providing Digital Transformation through end-to-end data solutions with 
                            its presence in Singapore & Indonesia. We believe in a holistic approach to support business decisions 
                            and build a top-quality and cost-effective strategy for businesses to operate successfully.  <br></br><br></br>

                            We are growing at a fast pace boosting overall value by having the larger organization embrace 
                            digital transformation in everyday business across the globe.<br></br>
                            We focus on driving business performance using big data analytics in order to create a significant opportunity. 
                            We believe in a fast and efficient business model which is reliable as well as cost effective.
                                   
                        </div>
                        <div className="about-title about-title2">Focused on customer success​</div>  
                    </div>
                    <OurValues/>
                    <WhyUs/>
                    <OurCompany/>
                </div> 
            </div>
        )
    }
}

export default About
