
import { combineReducers } from "redux";

import dologin from "./login";
import dataPGI from "./data";


export default combineReducers({
  dologin,
  dataPGI
});
