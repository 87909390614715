import React, { Component } from 'react';

class Footer extends Component {

  link() {
    window.open("https://www.coloursandpatterns.in/");
  };
  p() {
    window.open("https://www.privacypolicygenerator.info/live.php?token=JFpnFXEpNsPtzre1TVqDar1qqoU4fsfS");
  };
  
    render(){
     return (
       <div id="FOOTER" className="footer-component">
         <div className="footer">
             <div className="container"> 
         <span>
             <div className="box-footer">
                <span className="pfooter">Copyright ©PGI Data. All Rights Reserved</span>
                <span className="yfooter" onClick={() => this.p()}>Privacy Policy</span>
                <span className="yfooter" onClick={() => this.link()}>Designed by Colours and Patterns</span>
             </div>     
           </span>
           </div>
         </div>
       </div>
     );
     }
   }
   
   export default Footer;