import React, { Component } from 'react'

export class WhyUs extends Component {
    render() {
        return (
            <div>
                 <div id="OURCOMPANY" className="our-company-component">
            <div className="company">
              <div className="content">
                <h1 className="init" style={{ fontSize: "33pt" }}>
                  WHY US
                </h1>
                <div className="wrapper">  {/* flexrow */}
                  <div className="image">
                    <img src="images/diversified.png" className="analytic-logo" alt="" />
                  </div>
                  <div className="whyus-left" >
                    <h3>DIVERSIFIED EXPERIENCE</h3>
                    <ul className="list">
                      <li>20+ years of cumulative world-class analytics expertise</li>
                      <li>Experienced across different domains like Banking, Insurance, Retail, Energy, Telecommunication, Consumer Goods & Technology</li>
                      <li>Global provider of tailor-made end to end solutions.</li>
                    </ul>
                  </div>
                </div>
                <div className="wrapper">  {/* flexrow */}
                  <div className="image">
                    <img src="images/guaranteed.png" className="analytic-logo" alt="" />
                  </div>
                  <div className="whyus-left">
                    <h3>GUARANTEED RESULTS</h3>
                    <ul className="list">
                      <li>Proved accomplishment of implementing and upgrading end-to-end Big Data Analytics Solutions</li>
                      <li>Our clients across varied Business Verticals, recieved results with the right mix of Functionality, Technical skills & Consultants.</li>
                    </ul>
                  </div>
                </div>
                <div className="wrapper">  {/* flexrow */}
                  <div className="image">
                    <img src="images/boutique.png" className="analytic-logo" alt="" />
                  </div>
                  <div className="whyus-left">
                    <h3>BOUTIQUE PRACTICE</h3>
                    <ul className="list">
                      <li>Bringing industry best practices, correct tools and an expert team of Data Scientists and Data Architects.</li>
                      <li>Exercises domain knowledge & experiences that augments to better solution.</li>
                      <li>Hand holding and support till the end.</li>
                      <br></br>
                      <br></br>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>


            </div>
        )
    }
}

export default WhyUs
