import { all, fork } from "redux-saga/effects";

import loginSaga from "./login";
import postJobSaga from "./postjob";
import postUserJobSaga from "./postuserjob";
import getJobSaga from "./getjob";
import getUserJobSaga from "./getuserjob";
import getFileSaga from "./getcv";
import getInsightSaga from "./getinsight";
import postInsightSaga from "./postinsight"
import updateInsightSaga from "./updateinsight"
import deleteInsightSaga from "./deleteinsight"


export default function* rootSaga() {
  yield all([
    fork(loginSaga),
    fork(postJobSaga),
    fork(getJobSaga),
    fork(postUserJobSaga),
    fork(getUserJobSaga),
    fork(getFileSaga),
    fork(getInsightSaga),
    fork(postInsightSaga),
    fork(updateInsightSaga),
    fork(deleteInsightSaga)
  ]);
}